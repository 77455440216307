import { Box, Button, Divider, Drawer, Stack, TextField, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import StorefrontIcon from '@mui/icons-material/Storefront';
import { BrandStyleAPI, BrandStyleUpdateParams, businessServices, MediaAssetAPI } from "../../services/business.services";
import { BrandCardPreviewSource, MediaPickingMode, ROUTES, Z_INDEX_DRAWER } from "../constants";
import ProgressOverlay from "../loadingIndicator/progressOverlay";
import { LogoPickerFullScreen } from "../mediaPicker/logoPicker";
import { debounce, head, isEmpty } from "lodash";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import LogoButton from "../brandStyle/logoButton";
import { postIdeaServices } from "../../services/postIdeaServices";
import { RootState } from "../../app/store";
import { ColorPaletteAPI } from "../../services/colorPalette.services";
import { FontSetAPI } from "../../services/fontSet.services";
import BrandColorPaletteButton from "./brandColorPaletteButton";
import { BrandSettingsColorPaletteDrawer } from "./brandSettingsColorPaletteDrawer";
import BrandFontSetButton from "./brandFontSetButton";
import { BrandSettingsFontSetDrawer } from "./brandSettingsFontSetDrawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { currentUserBusinessId, getBrandStyleColorPalette, getBrandStyleFontSet, hasBrandStyle } from "../business/businessSlice";
import { hasUseBrandInBrandSlideInPostJson } from "../postIdea/postIdeaHelper";
import { ApplyBrandKitDialog } from "./applyBrandKitDialog";
import { eventTracker } from "../../helpers/eventTracker";
import { ImageStylePreferences } from "./imageStylePreferences";
import { BrandKitSettingsSection } from "./brandKitSettingsSection";
import PaletteIcon from "@mui/icons-material/Palette";
import { SettingsSuggest } from "@mui/icons-material";
import { DetailedToggle } from "./detailedToggle";
import { DrawerTitle } from "../typography/DrawerTitle";
import { BrandCardSelectorFullScreen } from "./brandCardSelector";
import { BrandCardPreview } from "./brandCardPreview";
import { convertPost, Post } from "../postIdea/postsSlice";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { PostIdeaContext } from "../context/postIdeaContext";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { errorReporter } from "../error/errorReporter";
import AlertBanner from "../alert/alertBanner";
import { logToConsoleError } from "../utils/devLoggingHelper";

export interface BrandingContentsProps
{
  source: BrandCardPreviewSource;
  onCloseFromModel?: () => void;
}

export function BrandingContents( props: BrandingContentsProps )
{
  const postIdea = useContext( PostIdeaContext ).postIdea;

  const dispatch = useDispatch();
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const [brandStyle, setBrandStyle] = useState<BrandStyleAPI>();
  const [businessName, setBusinessName] = useState<string>( "" );
  const [website, setWebsite] = useState<string>( "" );
  const [socialUsername, setSocialUsername] = useState<string>( "" );
  const [optionalText, setOptionalText] = useState<string>( "" );
  const [enabled, setEnabled] = useState<boolean>( false );
  const [logoMediaAsset, setLogoMediaAsset] = useState<MediaAssetAPI | undefined>( undefined );
  const [loading, setLoading] = useState( false );

  const [showLogoPicker, setShowLogoPicker] = useState( false );
  const [showColorPalettePicker, setShowColorPalettePicker] = useState( false );
  const [showFontSetPicker, setShowFontSetPicker] = useState( false );
  const [showDefaultSettingsDrawer, setShowDefaultSettingsDrawer] = React.useState<boolean>( false );

  const [applyBrandColorsFontsToBrandSlideEnabled, setApplyBrandColorsFontsToBrandSlideEnabled] = useState( false );
  const [applyBrandColorsFontsToPostIdeasEnabled, setApplyBrandColorsFontsToPostIdeasEnabled] = useState( false );
  const [colorPalette, setColorPalette] = useState<ColorPaletteAPI | undefined>( undefined );
  const [fontSet, setFontSet] = useState<FontSetAPI | undefined>( undefined );
  const [showBrandCardPreviewFullScreen, setShowBrandCardPreviewFullScreen] = useState( false );
  let hasPendingChanges = useRef<boolean>( false );
  const AUTO_SAVE_DELAY_MS = 2500;
  const debounceBrandStyle = useCallback( debounce( saveBrandStyle, AUTO_SAVE_DELAY_MS ), [] );
  const updatedBrandStyleParams = useRef<BrandStyleUpdateParams>( {} );
  const labelWidth = "100%";
  const toggleApplyBrandKitDisabled = !brandStyle?.color_palette && !brandStyle?.font_set;

  const hasAppliedBrandingInPostJson = postIdea && hasUseBrandInBrandSlideInPostJson( postIdea );
  const brandStyleFontSet = useSelector( ( state: RootState ) => getBrandStyleFontSet( state ) );
  const brandStyleColorPalette = useSelector( ( state: RootState ) => getBrandStyleColorPalette( state ) );
  const brandStyleFontSetBeforeEnteringEditor = useRef( brandStyleFontSet );
  const brandStyleColorPaletteBeforeEnteringEditor = useRef( brandStyleColorPalette );
  const [openApplyBrandKitDialog, setOpenApplyBrandKitDialog] = useState( false );

  const [brandCardPreviewPost, setBrandCardPreviewPost] = useState<Post | undefined>();
  const isCurrentBusinessSet = useSelector( ( state: RootState ) => !!currentUserBusinessId( state ) );
  const currentBusinessHasBrandStyle = useSelector( ( state: RootState ) => hasBrandStyle( state ) );

  useEffect( () =>
  {
    onMount();

    return () =>
    {
      onUnmount();
    }
  }, [] )

  useEffect( () =>
  {
    if ( isCurrentBusinessSet )
    {
      if ( !currentBusinessHasBrandStyle )
      {
        navigateWithSearchParams( ROUTES.USER_SETTINGS );
        return
      }
      onMount();
    }
  }, [isCurrentBusinessSet] );

  function onMount()
  {
    clearBrandStyleForSwitchBusiness();
    getBrandStyle();
    fetchUpdatedBrandCardPreview();
  }

  async function onUnmount()
  {
    if ( hasPendingChanges )
    {
      if ( !!debounceBrandStyle )
      {
        debounceBrandStyle.cancel();
      }

      await saveBrandStyle();
      await rebuildPostIdea();
    }
  }

  async function rebuildPostIdea()
  {
    if ( !!postIdea )
    {
      try
      {
        await postIdeaServices.rebuildPostIdea( postIdea.id );
      }
      catch (e)
      {
        logToConsoleError( "Error rebuilding post idea after brand slide change", e )
      }
    }
  }

  function clearBrandStyleForSwitchBusiness()
  {
    setBrandStyle( undefined );
    setBusinessName( "" );
    setWebsite( "" );
    setSocialUsername( "" );
    setOptionalText( "" );
    setLogoMediaAsset( undefined );
    setEnabled( false );
    setApplyBrandColorsFontsToBrandSlideEnabled( false );
    setApplyBrandColorsFontsToPostIdeasEnabled( false );
    setColorPalette( undefined );
    setFontSet( undefined );
    setBrandCardPreviewPost( undefined );
  }

  function getBrandStyle()
  {
    setLoading( true );
    businessServices.getBrandStyle().then( ( data: BrandStyleAPI ) =>
    {
      setLoading( false );

      if ( !!data )
      {
        setBrandStyle( data );

        updatedBrandStyleParams.current.brand_style_id = data.id;

        if ( !!data.business_name )
        {
          setBusinessName( data.business_name );
        }

        if ( !!data.website )
        {
          setWebsite( data.website );
        }

        if ( !!data.social_username )
        {
          setSocialUsername( data.social_username );
        }

        if ( !!data.optional_text )
        {
          setOptionalText( data.optional_text );
        }

        if ( !!data.logo )
        {
          setLogoMediaAsset( data.logo )
        }

        if ( data.enabled !== undefined )
        {
          setEnabled( data.enabled )
        }

        if ( data.use_brand_in_brand_slide !== undefined )
        {
          setApplyBrandColorsFontsToBrandSlideEnabled( data.use_brand_in_brand_slide )
        }

        if ( data.use_brand_in_post_idea !== undefined )
        {
          setApplyBrandColorsFontsToPostIdeasEnabled( data.use_brand_in_post_idea )
        }

        if ( data.color_palette !== undefined )
        {
          setColorPalette( data.color_palette );
        }

        if ( data.font_set !== undefined )
        {
          setFontSet( data.font_set );
        }
      }

    } ).catch( ( error ) =>
    {
      setLoading( false );
      logToConsoleError( error );
    } );
  }

  function handleUseCustomBrandingClicked( event: React.ChangeEvent<HTMLInputElement> )
  {
    hasPendingChanges.current = true;
    const newValue = event.target.checked;
    setEnabled( newValue );
    updatedBrandStyleParams.current.enabled = newValue;
    debounceBrandStyle();
    eventTracker.logBrandSettingsBrandSlideToggled( newValue );
  }

  function handleOnBusinessNameChanged( event: React.ChangeEvent<HTMLInputElement> )
  {
    hasPendingChanges.current = true;
    const newValue = event.target.value;
    setBusinessName( newValue );
    updatedBrandStyleParams.current.business_name = newValue;
    debounceBrandStyle();
  }

  function handleOnWebsiteChanged( event: React.ChangeEvent<HTMLInputElement> )
  {
    hasPendingChanges.current = true;
    const newValue = event.target.value;
    setWebsite( newValue );
    updatedBrandStyleParams.current.website = newValue;
    debounceBrandStyle();
  }

  function handleOnSocialUsernameChanged( event: React.ChangeEvent<HTMLInputElement> )
  {
    hasPendingChanges.current = true;
    const newValue = event.target.value;
    setSocialUsername( newValue );
    updatedBrandStyleParams.current.social_username = newValue;
    debounceBrandStyle();
  }

  function handleOnOptionalTextChanged( event: React.ChangeEvent<HTMLInputElement> )
  {
    hasPendingChanges.current = true;
    const newValue = event.target.value;
    setOptionalText( event.target.value );
    updatedBrandStyleParams.current.optional_text = newValue;
    debounceBrandStyle();
  }

  async function commitBrandStyleChanges( brandStyleUpdateParams: BrandStyleUpdateParams )
  {
    setLoading( true );
    try
    {
      await businessServices.updateBrandStyle( brandStyleUpdateParams )
      hasPendingChanges.current = false;
      fetchUpdatedBrandCardPreview();
    }
    catch (error)
    {
      reportErrorToSentryAndUser( error );
    }
    finally
    {
      setLoading( false );
    }
  }

  function reportErrorToSentryAndUser( error )
  {
    errorReporter.reportErrorToSentry( error );
    dispatch( setAlertMessage( errorAlert( "Unable to save brand. Please try again.", "top" ) ) );
  }

  function fetchUpdatedBrandCardPreview()
  {
    businessServices.getBrandCardPreview().then( ( brandCardPreview ) =>
    {
      if ( !!brandCardPreview )
      {
        const convertedPost = convertPost( brandCardPreview );
        setBrandCardPreviewPost( convertedPost );
      }
    } );
  }

  async function saveBrandStyle()
  {
    const { brand_style_id, ...otherProperties } = updatedBrandStyleParams.current;
    const brandStyleParamsChanged = !isEmpty( otherProperties );

    if ( brandStyleParamsChanged )
    {
      await commitBrandStyleChanges( updatedBrandStyleParams.current );
    }
  }

  function handleLogoPickerClicked()
  {
    setShowLogoPicker( true );
  }

  function handleClosedLogoPicker()
  {
    setShowLogoPicker( false );
  }

  function handleColorPalettePickerClicked()
  {
    setShowColorPalettePicker( true );
  }

  function handleFontSetPickerClicked()
  {
    setShowFontSetPicker( true );
  }

  async function handleSelectedMediaAssets( mediaAssets: MediaAssetAPI[] )
  {
    try
    {
      hasPendingChanges.current = true;
      let logoMediaAssetId = head( mediaAssets )?.id;
      const brandStyleUpdateParams: BrandStyleUpdateParams = {
        brand_style_id: updatedBrandStyleParams.current.brand_style_id,
        logo_media_asset_id: logoMediaAssetId
      };
      await commitBrandStyleChanges( brandStyleUpdateParams );
      hasPendingChanges.current = false;
      getBrandStyle();
      eventTracker.logBrandLogoChanged( logoMediaAssetId );
      handleClosedLogoPicker();
    }
    catch (error)
    {
      reportErrorToSentryAndUser( error );
    }
  }

  function handleUsePostStyleInBrandCard( event: React.ChangeEvent<HTMLInputElement> )
  {
    hasPendingChanges.current = true;
    const newValue = !event.target.checked;
    setApplyBrandColorsFontsToBrandSlideEnabled( newValue );
    updatedBrandStyleParams.current.use_brand_in_brand_slide = newValue;
    debounceBrandStyle();
    eventTracker.logBrandSettingsBrandKitForBrandSlideToggled( newValue );
  }

  function handleApplyBrandColorsFontsToPostIdeasClicked( event: React.ChangeEvent<HTMLInputElement> )
  {
    hasPendingChanges.current = true;
    const newValue = event.target.checked;
    setApplyBrandColorsFontsToPostIdeasEnabled( newValue );
    updatedBrandStyleParams.current.use_brand_in_post_idea = newValue;
    debounceBrandStyle();
    eventTracker.logBrandSettingsBrandKitForPostIdeasToggled( newValue );
  }

  function handleDrawerClose()
  {
    setShowColorPalettePicker( false );
    setShowFontSetPicker( false );
    setShowDefaultSettingsDrawer( false );
  }

  async function applyColorPaletteSlug( colorPaletteSlug: string )
  {
    updatedBrandStyleParams.current.color_palette_slug = colorPaletteSlug;

    await saveBrandStyle();
    getBrandStyle();
    eventTracker.logBrandColorPaletteSelected( colorPaletteSlug );
  }

  async function applyFontSetSlug( fontSetSlug: string )
  {
    updatedBrandStyleParams.current.font_set_slug = fontSetSlug;

    await saveBrandStyle();
    getBrandStyle();
    eventTracker.logBrandFontSetSelected( fontSetSlug );
  }

  function handleCloseButtonClicked()
  {
    if ( !!postIdea && !hasAppliedBrandingInPostJson )
    {
      const brandStyleFontSetChanged = (brandStyleFontSetBeforeEnteringEditor.current?.slug !== brandStyleFontSet?.slug);
      const brandStyleColorPaletteChanged = (brandStyleColorPaletteBeforeEnteringEditor.current?.slug !== brandStyleColorPalette?.slug);

      if ( brandStyleFontSetChanged || brandStyleColorPaletteChanged )
      {
        setOpenApplyBrandKitDialog( true );
      }
      else
      {
        closeModel();
      }
    }
    else
    {
      closeModel();
    }
  }

  function closeModel()
  {
    if ( props.onCloseFromModel )
    {
      props.onCloseFromModel();
    }
  }

  async function handleCloseApplyBrandKitDialog( applyBrandKit: boolean )
  {
    if ( applyBrandKit )
    {
      if ( postIdea )
      {
        await postIdeaServices.applyBrandStyle( postIdea.id, { use_brand_in_brand_slide: true } );
        await businessServices.getBrandStyle();
      }
      setOpenApplyBrandKitDialog( false );
    }
    else
    {
      setOpenApplyBrandKitDialog( false );
    }
    closeModel();
  }

  function handlePreviewBrandCardButtonClicked()
  {
    saveBrandStyle().then( () =>
    {
      setShowBrandCardPreviewFullScreen( true );
    } );
    eventTracker.logBrandCardPreviewClicked( props.source );
  }

  function handleCloseBrandCardPreview()
  {
    fetchUpdatedBrandCardPreview();
    setShowBrandCardPreviewFullScreen( false );
  }

  function handleModifyBrandSettingsClicked()
  {
    setShowDefaultSettingsDrawer( true );
  }

  return (
    <Stack spacing={10} width="100%" sx={{ pb: 20 }}>
      {props.onCloseFromModel && <Box>
        <IconButton
          aria-label="close"
          onClick={handleCloseButtonClicked}>
          <CloseIcon fontSize="inherit"/>
        </IconButton>
      </Box>}
      <Stack width="100%">
        {!postIdea && <BrandKitSettingsSection handleModifyBrandSettingsClicked={handleModifyBrandSettingsClicked}/>}
      </Stack>

      {!postIdea && <Divider sx={{ width: "100%" }}/>}

      <Stack direction="row" alignItems="center">
        <StorefrontIcon sx={{ mr: 6 }}/>
        <Typography variant="h6">Name & Details</Typography>
      </Stack>
      <TextField label="Business Name"
                 variant="outlined"
                 sx={{ width: labelWidth }}
                 value={businessName}
                 onChange={handleOnBusinessNameChanged}/>
      <TextField label="Website"
                 variant="outlined"
                 sx={{ width: labelWidth }}
                 value={website}
                 onChange={handleOnWebsiteChanged}/>
      <TextField label="@MySocialUsername"
                 variant="outlined"
                 sx={{ width: labelWidth }}
                 value={socialUsername}
                 onFocus={() =>
                 {
                   if ( !socialUsername )
                   {
                     setSocialUsername( "@" );
                   }
                 }}
                 onChange={handleOnSocialUsernameChanged}/>
      <TextField label="Optional Text (Tagline, CTA, Licensing, Etc)"
                 variant="outlined"
                 sx={{ width: labelWidth }}
                 multiline
                 maxRows={3}
                 value={optionalText}
                 onChange={handleOnOptionalTextChanged}/>

      <Divider sx={{ width: "100%" }}/>

      <Stack direction="row" alignItems="center">
        <PaletteIcon sx={{ mr: 6 }}/>
        <Typography variant="h6">Visual Identity</Typography>
      </Stack>

      <Stack direction="row" alignItems="center" sx={{ width: "100%", flexWrap: "wrap" }}>
        <LogoButton mediaAsset={logoMediaAsset} handleLogoPickerClicked={handleLogoPickerClicked}/>
        <BrandColorPaletteButton colorPalette={colorPalette}
                                 handleColorPalettePickerClicked={handleColorPalettePickerClicked}/>
        <BrandFontSetButton handleFontSetPickerClicked={handleFontSetPickerClicked} fontSet={fontSet}/>
      </Stack>


      <Stack direction="row" alignItems="center">
        <ContactPageIcon sx={{ mr: 6 }}/>
        <Typography variant="h6">Brand Card</Typography>
      </Stack>

      {!showBrandCardPreviewFullScreen && brandCardPreviewPost && <Box>
        <BrandCardPreview sx={{ width: "100px", height: "192px", mt: 0 }}
                          post={brandCardPreviewPost}
                          showReplay={false}
                          showInstagramOverlay={false}/>
      </Box>}

      <Button variant="outlined" sx={{ mt: 10 }} onClick={handlePreviewBrandCardButtonClicked}>
        Select Brand Card Layout
      </Button>

      {!postIdea && <Divider sx={{ width: "100%" }}/>}

      {!postIdea && <ImageStylePreferences/>}

      {
        loading && <ProgressOverlay/>
      }
      <LogoPickerFullScreen title="Choose logo"
                            manageOpenStateExternally={true}
                            externalStateOpen={showLogoPicker}
                            handleClose={handleClosedLogoPicker}
                            processSelectedMediaAssets={handleSelectedMediaAssets}
                            currentMediaUrls={[]}
                            hideTopBar={true}
                            mediaPickingMode={MediaPickingMode.Overwrite}/>

      {brandStyle && <Drawer
        anchor="bottom"
        sx={{ zIndex: Z_INDEX_DRAWER }}
        open={showColorPalettePicker}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <BrandSettingsColorPaletteDrawer brandStyle={brandStyle}
                                           handleDrawerClose={handleDrawerClose}
                                           applyColorPaletteSlug={applyColorPaletteSlug}/>
        </Box>
      </Drawer>}

      {brandStyle && <Drawer
        anchor="bottom"
        sx={{ zIndex: Z_INDEX_DRAWER }}
        open={showFontSetPicker}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <BrandSettingsFontSetDrawer brandStyle={brandStyle}
                                      handleDrawerClose={handleDrawerClose}
                                      applyFontSetSlug={applyFontSetSlug}/>
        </Box>
      </Drawer>}

      {!postIdea && showDefaultSettingsDrawer && <Drawer
        anchor="bottom"
        onClose={handleDrawerClose}
        sx={{ zIndex: Z_INDEX_DRAWER }}
        open={showDefaultSettingsDrawer}>
        <Stack sx={{ maxWidth: "400px", margin: "auto" }} alignItems="center">
          <Stack direction="row" alignItems="center" sx={{ mb: 4 }}>
            <SettingsSuggest sx={{ mr: 6 }}/>
            <DrawerTitle>Default brand settings</DrawerTitle>
          </Stack>

          <DetailedToggle disabled={toggleApplyBrandKitDisabled}
                          checked={applyBrandColorsFontsToPostIdeasEnabled}
                          title="Apply brand to post content"
                          description="Use brand fonts & colors on all new posts."
                          disabledHint="Choose brand fonts or colors to enable this option"
                          onChange={handleApplyBrandColorsFontsToPostIdeasClicked}/>
          <DetailedToggle disabled={false}
                          checked={enabled}
                          title="Add Brand Card to end of post"
                          description="Automatically include your brand card on new posts."
                          disabledHint=""
                          onChange={handleUseCustomBrandingClicked}/>
          <DetailedToggle disabled={toggleApplyBrandKitDisabled}
                          checked={!applyBrandColorsFontsToBrandSlideEnabled}
                          title="Use post style in Brand Card"
                          description="Brand card will match post styles if different from brand."
                          disabledHint=""
                          onChange={handleUsePostStyleInBrandCard}/>
        </Stack>
      </Drawer>}
      {brandStyle && !!postIdea && <ApplyBrandKitDialog openApplyBrandKitDialog={openApplyBrandKitDialog}
                                                        closeApplyBrandKitDialog={handleCloseApplyBrandKitDialog}/>}
      {showBrandCardPreviewFullScreen && <BrandCardSelectorFullScreen showInstagramOverlay={false}
                                                                      manageOpenStateExternally={true}
                                                                      externalStateOpen={showBrandCardPreviewFullScreen}
                                                                      hideTopBar={true}
                                                                      handleClose={handleCloseBrandCardPreview}
                                                                      source={props.source}/>}
      <AlertBanner/>
    </Stack>
  )
}
