import React, { useEffect } from 'react'
import { businessServices, FACEBOOK_INSTAGRAM, FACEBOOK_PAGE, SocialNetworkAccountAPI } from "../../services/business.services";
import { SocialMediaAccountRow } from "./SocialMediaAccountRow";
import { Box, Stack, Typography } from "@mui/material";
import { ConnectFacebookPagesButton } from "../buttons/connectFacebookPagesButton";
import { ConnectFacebookInstagramButton } from "../buttons/connectFacebookInstagramButton";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { socialNetworkAccountHelper } from "../../helpers/socialNetworkAccountHelper";
import { CONNECTS_WITH_RIPL_TEXT } from "../constants";
import { SocialNetworkAccountsBadBrowserWall } from "../editing/socialNetworkAccountsBadBrowserWall";
import { currentUserBusinessId } from "../business/businessSlice";
import { RootState } from "../../app/store";
import { useSelector } from "react-redux";

export function SocialNetworkAccountsSection()
{
  const [socialNetworkAccounts, setSocialNetworkAccounts] = React.useState<SocialNetworkAccountAPI[]>( [] );
  const isShowingConnectButton = socialNetworkAccountHelper.hasYetToConnectBothSocialNetworks( socialNetworkAccounts );

  const isCurrentBusinessSet = useSelector( ( state: RootState ) => !!currentUserBusinessId( state ) );

  useEffect( () =>
  {
    businessServices.listSocialNetworkAccounts().then( ( response ) =>
    {
      setSocialNetworkAccounts( response.social_network_accounts );
    } )

  }, [] );

  useEffect( () =>
  {
    if ( isCurrentBusinessSet )
    {
      businessServices.listSocialNetworkAccounts().then( ( response ) =>
      {
        setSocialNetworkAccounts( response.social_network_accounts );
      } )
    }
  }, [isCurrentBusinessSet] );

  function getFacebookRow()
  {
    const accountType = FACEBOOK_PAGE;
    const connectedFacebookAccount = socialNetworkAccountHelper.getConnectedFacebookPage( socialNetworkAccounts );
    if ( connectedFacebookAccount )
    {
      const otherSocialNetworksToSwitchTo = socialNetworkAccountHelper.getSocialNetworksByType( socialNetworkAccounts, accountType );
      return <SocialMediaAccountRow socialNetworkAccount={connectedFacebookAccount}
                                    otherSocialNetworksToSwitchTo={otherSocialNetworksToSwitchTo}
                                    fromSettings={true}/>
    }
    else
    {
      return <SocialNetworkAccountsBadBrowserWall>
        <ConnectFacebookPagesButton fromSettings={true}/>
      </SocialNetworkAccountsBadBrowserWall>
    }
  }

  function getInstagramRow()
  {
    const accountType = FACEBOOK_INSTAGRAM;
    const connectedInstagramAccount = socialNetworkAccountHelper.getConnectedInstagram( socialNetworkAccounts );
    if ( connectedInstagramAccount )
    {
      const otherSocialNetworksToSwitchTo = socialNetworkAccountHelper.getSocialNetworksByType( socialNetworkAccounts, accountType );
      return <SocialMediaAccountRow socialNetworkAccount={connectedInstagramAccount}
                                    otherSocialNetworksToSwitchTo={otherSocialNetworksToSwitchTo}
                                    fromSettings={true}/>
    }
    else
    {
      return <SocialNetworkAccountsBadBrowserWall>
        <ConnectFacebookInstagramButton fromSettings={true}/>
      </SocialNetworkAccountsBadBrowserWall>
    }
  }

  function updateSocialNetworkAccounts( socialNetworkAccounts: SocialNetworkAccountAPI[] )
  {
    setSocialNetworkAccounts( socialNetworkAccounts );
  }

  return (
    <>
      <SocialNetworkAccountsContext.Provider value={{ socialNetworkAccounts, updateSocialNetworkAccounts }}>
        <Stack sx={{ width: "100%", px: 5, py: 5 }}>

          <Stack sx={{ position: "relative", width: "100%" }} spacing={5}>
            {getFacebookRow()}
            {getInstagramRow()}
          </Stack>

          {isShowingConnectButton && <SocialNetworkAccountsBadBrowserWall>
            <Box textAlign="left" sx={{ pl: 5 }}>
              <Typography variant={"caption"} sx={{ color: "black", }}>{CONNECTS_WITH_RIPL_TEXT}</Typography>
            </Box>
          </SocialNetworkAccountsBadBrowserWall>}
        </Stack>
      </SocialNetworkAccountsContext.Provider>
    </>
  )
}

