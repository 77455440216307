import React from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { facebookServices } from "../../services/facebook.services";
import { getFacebookAppId } from '../../constants';
import { businessServices } from "../../services/business.services";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SyncIcon from "@mui/icons-material/Sync";
import { logToConsole, logToConsoleError } from "../utils/devLoggingHelper";

interface ReactFacebookRenderProps
{
  isSdkLoaded?: boolean;
  isProcessing?: boolean;
  isDisabled?: boolean;
  onClick( event: React.MouseEvent ): void;
}

interface RefreshFacebookButtonProps
{
  handleRefreshComplete?(): void;
  iconOnly?: boolean;
}

export function RefreshFacebookButton( props: RefreshFacebookButtonProps )
{
  const facebookPermissionsScope = facebookServices.getFacebookPagesScope();
  const [showLoader, setShowLoader] = React.useState( false );

  const handleClick = ( event: React.MouseEvent ) =>
  {
    setShowLoader( true );
    requestConnect();
  }

  function connectionComplete()
  {
    if ( !!props.handleRefreshComplete )
    {
      props.handleRefreshComplete();
    }
  }

  const onFacebookConnectSuccess = ( facebookId: number,
                                     name: string,
                                     facebookAccessToken: string ) =>
  {
    businessServices.clientFacebookConnect( facebookId, facebookAccessToken ).then( result =>
    {
      connectionComplete();
    } ).catch( error =>
    {
      logToConsoleError( "error facebook connected", error );
      setShowLoader( false );
    } )
      .finally( () =>
      {
        setShowLoader( false );
      } );
  }

  const onFacebookConnectFailure = ( failureData?: { response?: string, errorText?: string, permissionsDenied?: boolean } ) =>
  {
    setShowLoader( false );
    logToConsoleError( "facebook connect failure", failureData );
  }

  const handleFacebookResponse = async ( response ) =>
  {
    if ( response && response.id && response.accessToken )
    {
      const accessToken = response.accessToken;
      const facebookIsAccessible = await facebookServices.verifyUserHasFacebookPagePermissions( accessToken );
      if ( facebookIsAccessible )
      {
        onFacebookConnectSuccess( response.id, response.name, accessToken );
      }
      else
      {
        onFacebookConnectFailure(
          { permissionsDenied: true, errorText: "User did not grant required Facebook permissions." },
        );
      }
    }
    else
    {
      onFacebookConnectFailure( { response, errorText: "Facebook connect failed." } );
    }
  }

  function requestConnect()
  {
    logToConsole( "connect facebook page started" );
  }

  return (
    <FacebookLogin
      appId={getFacebookAppId()}
      className={""}
      autoLoad={false}
      reAuthenticate={true}
      disableMobileRedirect={true}
      scope={facebookPermissionsScope}
      fields="name,email,picture"
      version={facebookServices.GRAPH_API_VERSION}
      render={( renderProps: ReactFacebookRenderProps ) =>
      {
        if ( props.iconOnly )
        {
          const loadingClass = showLoader ? "flipAndRotate" : "";
          return <IconButton onClick={renderProps.onClick}
                             sx={{ transform: "scaleX(-1)", color: "red", pointerEvents: showLoader ? "none" : "default" }} className={loadingClass}
          ><SyncIcon/></IconButton>
        }
        else
        {
          const buttonStyle = {
            ml: "auto", mr: 1, backgroundColor: "white", color: "#DB1B50", fontSize: "10px", fontWeight: "400",
            '&:hover': { backgroundColor: '#F2F2F2', color: '#CB0046' }, pointerEvents: showLoader ? "none" : "default"
          };
          const buttonText = showLoader ? "Refreshing..." : "Refresh";
          return <Button onClick={renderProps.onClick} variant={"contained"}
                         sx={buttonStyle}>{buttonText}</Button>
        }
      }}
      onClick={handleClick}
      callback={handleFacebookResponse}/>
  )
}
