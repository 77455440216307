import React from 'react'
import { Button, Drawer, Stack, Typography } from "@mui/material";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { facebookServices } from "../../services/facebook.services";
import { getFacebookAppId } from '../../constants';
import { ConfirmFacebookAccount } from '../confirmSocialNetworkAccount/confirmFacebookAccount';
import { ConfirmFacebookPageAccount } from "../confirmSocialNetworkAccount/confirmFacebookPageAccount";
import { businessServices } from "../../services/business.services";
import { setAlertMessage, warningAlert } from "../alert/alertSlice";
import { useDispatch } from "react-redux";
import { eventTracker } from "../../helpers/eventTracker";
import FacebookIcon from "@mui/icons-material/Facebook";
import { ShareDestinationToggle } from "../editing/ShareDestinationToggle";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { logToConsole, logToConsoleError } from "../utils/devLoggingHelper";

interface ReactFacebookRenderProps
{
  isSdkLoaded?: boolean;
  isProcessing?: boolean;
  isDisabled?: boolean;
  onClick( event: React.MouseEvent ): void;
}

interface ConnectFacebookPagesButtonProps
{
  fromSettings?: boolean;
  handleConnectionSucceeded?(): void;
  handleConnectionFailed?(): void;
  handleConnectionStarted?(): void;
}

export function ConnectFacebookPagesButton( props: ConnectFacebookPagesButtonProps )
{
  const dispatch = useDispatch();
  const facebookPermissionsScope = facebookServices.getFacebookPagesScope();
  const [drawerOpened, setDrawerOpened] = React.useState( false );
  const [profileUrl, setProfileUrl] = React.useState( "" );
  const [profileName, setProfileName] = React.useState( "" );
  const [profileAccessToken, setProfileAccessToken] = React.useState( "" );
  const [profileId, setProfileId] = React.useState( 0 );
  const [showLoader, setShowLoader] = React.useState( false );
  const [confirmingFacebookPage, setConfirmingFacebookPage] = React.useState( false );
  const socialNetworkAccountsContext = React.useContext( SocialNetworkAccountsContext );

  const handleClick = ( event: React.MouseEvent ) =>
  {
    requestConnect();
  }

  const onSwitchAccounts = ( event: React.MouseEvent ) =>
  {
    logToConsole( "switch accounts facebook pages" );
    window.FB.logout();
    dispatch( setAlertMessage( warningAlert( "Please log out of Facebook in your browser before trying to connect again." ) ) );
    handleCancelConnectionAttempt();
  }

  function facebookPageConnectionComplete()
  {
    eventTracker.logFacebookPageConnected();
    if ( !!props.handleConnectionSucceeded )
    {
      props.handleConnectionSucceeded();
    }
  }

  const onConfirm = async ( event: React.MouseEvent ) =>
  {
    setShowLoader( true );
    try
    {
      const result = await businessServices.clientFacebookConnect( profileId, profileAccessToken );
      logToConsole( "result facebook connected", result );

      if ( result.has_facebook_page_accounts )
      {
        const socialNetworkAccountsResponseAPI = await businessServices.listSocialNetworkAccounts();
        socialNetworkAccountsContext.updateSocialNetworkAccounts( socialNetworkAccountsResponseAPI.social_network_accounts );
        setShowLoader( false );
        setConfirmingFacebookPage( true );
      }
      else
      {
        setShowLoader( false );
        closeDrawer();
        facebookPageConnectionComplete();
      }
    }
    catch (error)
    {
      logToConsoleError( "error facebook connected", error );
      setShowLoader( false );
      closeDrawer();
    }
  }

  const onFacebookConnectSuccess = ( facebookId: number,
                                     name: string,
                                     facebookAccessToken: string ) =>
  {
    const profileImageUrl = facebookServices.getFacebookProfileImageUrl( facebookId.toString() );
    setProfileUrl( profileImageUrl );
    setProfileName( name );
    setProfileAccessToken( facebookAccessToken );
    setProfileId( facebookId );
    openDrawer();
  }

  const onFacebookConnectFailure = ( failureData?: { response?: string, errorText?: string, permissionsDenied?: boolean } ) =>
  {
    logToConsoleError( "facebook connect failure", failureData );
    if ( !!props.handleConnectionFailed )
    {
      props.handleConnectionFailed();
    }
  }

  const handleFacebookResponse = async ( response ) =>
  {
    if ( response && response.id && response.accessToken )
    {
      const accessToken = response.accessToken;
      const facebookIsAccessible = await facebookServices.verifyUserHasFacebookPagePermissions( accessToken );
      if ( facebookIsAccessible )
      {
        onFacebookConnectSuccess( response.id, response.name, accessToken );
      }
      else
      {
        onFacebookConnectFailure(
          { permissionsDenied: true, errorText: "User did not grant required Facebook permissions." },
        );
      }
    }
    else
    {
      onFacebookConnectFailure( { response, errorText: "Facebook connect failed." } );
    }
  }

  function requestConnect()
  {
    logToConsole( "connect facebook page started" );
    eventTracker.logAcceptConnectFacebookPageClicked();
    if ( !!props.handleConnectionStarted )
    {
      props.handleConnectionStarted();
    }
  }

  function reloadSocialNetworkAccounts()
  {
    businessServices.listSocialNetworkAccounts().then( ( socialNetworkAccountsResponseAPI ) =>
      {
        socialNetworkAccountsContext.updateSocialNetworkAccounts( socialNetworkAccountsResponseAPI.social_network_accounts );
      }
    );
  }

  return (
    <FacebookLogin
      appId={getFacebookAppId()}
      className={""}
      autoLoad={false}
      reAuthenticate={true}
      disableMobileRedirect={true}
      scope={facebookPermissionsScope}
      fields="name,email,picture"
      version={facebookServices.GRAPH_API_VERSION}
      render={( renderProps: ReactFacebookRenderProps ) =>
      {
        const { onClick } = renderProps;
        return (
          <>
            <Drawer
              anchor={"bottom"}
              open={drawerOpened}
              onClose={handleCancelConnectionAttempt}
              sx={{ textAlign: 'center', m: 1, zIndex: 1500 }}
            >
              {!confirmingFacebookPage && <ConfirmFacebookAccount profileUrl={profileUrl}
                                                                  profileName={profileName}
                                                                  onSwitchAccounts={onSwitchAccounts}
                                                                  onConfirm={onConfirm}
                                                                  showLoader={showLoader}/>}

              {confirmingFacebookPage && <ConfirmFacebookPageAccount handleCancel={handleFinishedConfirmingFacebookPage} isInitialConnect={true}/>}
            </Drawer>
            {!props.fromSettings && <ShareDestinationToggle icon={<FacebookIcon sx={{ width: 30, height: 30, mt: 3, color: "black" }}/>}
                                                            label={"Connect a Facebook Page*"}
                                                            onClick={onClick}
                                                            checked={false}
            />}
            {props.fromSettings && <Stack
              sx={{ mt: 10, display: "flex", flexDirection: "row", gap: "10px", justifyContent: "space-between", width: "100%" }}>
              <Stack sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                <FacebookIcon sx={{ width: 30, height: 30, color: "black" }}/>
                <Typography>Facebook Page*</Typography>
              </Stack>
              <Button variant={"contained"} onClick={onClick} sx={{ minWidth: "100px" }}>Connect</Button>
            </Stack>}
          </>)
          ;
      }}
      onClick={handleClick}
      callback={handleFacebookResponse}/>
  )

  function handleFinishedConfirmingFacebookPage()
  {
    reloadSocialNetworkAccounts();
    facebookPageConnectionComplete();
    closeDrawer();
  }

  function handleCancelConnectionAttempt()
  {
    onFacebookConnectFailure();
    closeDrawer();
  }

  function closeDrawer()
  {
    setDrawerOpened( false );
  }

  function openDrawer()
  {
    setDrawerOpened( true );
  }

}
