import { Box, Button, Divider, Link, Stack, Typography } from "@mui/material";
import { SignOutButton } from "../registration/signOutButton";
import React, { ReactNode, useEffect, useState } from "react";
import { NewBusinessButton } from "../buttons/newBusinessButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { isDevModeEnabled } from "../featureFlags/featureFlagsSlice";
import { UserSettingsBusinessDetails } from "./userSettingsBusinessDetails";
import AlertBanner from "../alert/alertBanner";
import {
  getIsInternalUser,
  hasEmail,
  hasMultipleBusinesses,
  hasPhoneNumber,
  isGuestUser,
  isSubscribedUser,
  isUserLoggedIn,
  isUserVerificationRequired,
  wasSubscribedUser
} from "../user/userSlice";
import {
  ALKAI_BLOG_URL,
  ALKAI_FAQ_NEW_TICKET_URL,
  ALKAI_FAQ_URL,
  ALKAI_GET_THE_APP_URL,
  CREATE_ACCOUNT_SHOWN_VIA_USER_SETTINGS,
  FACEBOOK_ALKAI_URL,
  INSTAGRAM_ALKAI_URL,
  PRIVACY_POLICY_URL,
  ROUTE_PARAM,
  ROUTE_SEO,
  ROUTES,
  SURVEY_MONKEY_JUNE_2024_SURVEY_ID,
  TERMS_OF_USE_URL
} from "../constants";
import { CreateAccountOrSignIn } from "../registration/createAccountOrSignIn";
import { authenticationServices } from "../../services/authentication.services";
import { ApptimizeVariablesLogView } from "../apptimize/appitimizeVariablesLogView";
import { ScheduledDowngradeAttributes, stripeServices } from "../../services/stripe.services";
import { setShowAddMultipleBusinessesUpsell, setUpsellShown } from "../ui/uiSlice";
import { eventTracker } from "../../helpers/eventTracker";
import { UPSELL_SOURCE_USER_SETTINGS } from "../../helpers/trackingConstants";
import { ALKAI_VERSION } from "../../constants";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { getBusinessId, hasBrandStyle, hasCompletedFirstRun, isSubscribedAllNotifications } from "../business/businessSlice";
import { Helmet } from "react-helmet";
import { SurveyLink } from "./surveyLink";
import { PostingStrategyQuizFullScreenDialog } from "../postingStrategyQuiz/postingStrategyQuizFullScreenDialog";
import { businessServices } from "../../services/business.services";
import { NotificationSubscriptionToggle } from "./NotificationSubscriptionToggle";
import { UrlUtils } from "../utils/urlUtils";
import { useSearchParams } from "react-router-dom";
import useRemoveSearchParamByKey from "../hooks/useRemoveSearchParamByKey";
import { setApplicationLoadingState } from "../loadingIndicator/loadingSlice";
import { errorAlert, infoAlert, setAlertMessage } from "../alert/alertSlice";
import { UserSettingsRow } from "./userSettingsRow";
import StoreOutlined from "@mui/icons-material/StoreOutlined";
import { DoNotDisturbOnOutlined, SellOutlined } from "@mui/icons-material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import ImportantDevicesOutlinedIcon from '@mui/icons-material/ImportantDevicesOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import { ConfirmDialog } from "../ui/confirmDialog";
import { DateUtils } from "../utils/dateUtils";
import { SocialMediaAccountsSummary } from "./socialMediaAccountsSummary";

export function UserSettings()
{
  const dispatch = useDispatch();
  const navigateWithSearchParams = useNavigateWithSearchParams();

  const devModeEnabled = useSelector( ( state: RootState ) => isDevModeEnabled( state ) );
  const userIsLoggedIn = useSelector( ( state: RootState ) => isUserLoggedIn( state ) );
  const isGuest = useSelector( ( state: RootState ) => isGuestUser( state ) );
  const isVerificationRequired = useSelector( ( state: RootState ) => isUserVerificationRequired( state ) );
  const [showCreateLogin, setShowCreateLogin] = useState<boolean>( isVerificationRequired );
  const [showPostingStrategyQuiz, setShowPostingStrategyQuiz] = useState<boolean>( false );
  const shouldShowCheckoutButton = useSelector( ( state: RootState ) => !isSubscribedUser( state ) );
  const wasSubscribed = useSelector( ( state: RootState ) => wasSubscribedUser( state ) );
  const isSubscribed = useSelector( ( state: RootState ) => isSubscribedUser( state ) );
  const shouldShowCustomerPortal = isSubscribed || wasSubscribed;
  const shouldShowBrandStyle = useSelector( ( state: RootState ) => hasBrandStyle( state ) );
  const businessId = useSelector( ( state: RootState ) => getBusinessId( state ) ) || "";
  const isSubscribedFromAllNotifications = useSelector( ( state: RootState ) => isSubscribedAllNotifications( state ) );
  const [isSubscribedFromAllNotificationsToggleOn, setIsSubscribedFromAllNotificationsToggleOn] = useState<boolean>(
    isSubscribedFromAllNotifications );

  const hasPhoneChannel = useSelector( ( state: RootState ) => hasPhoneNumber( state ) );
  const hasEmailChannel = useSelector( ( state: RootState ) => hasEmail( state ) );
  const hasCommunicationChannel = hasPhoneChannel || hasEmailChannel;
  const [searchParams,] = useSearchParams();
  const navigatedWithUpsellParam = UrlUtils.isParamValueTrue( searchParams.get( ROUTE_PARAM.SETTINGS_TAB_UPSELL ) );
  const returnFromStripe = UrlUtils.isParamValueTrue( searchParams.get( ROUTE_PARAM.RETURN_FROM_STRIPE ) );
  const hasFinishedFirstRun = useSelector( ( state: RootState ) => hasCompletedFirstRun( state ) );
  const hasMoreThanOneBusiness = useSelector( ( state: RootState ) => hasMultipleBusinesses( state ) );
  const isInternalUser = useSelector( ( state: RootState ) => getIsInternalUser( state ) );
  const removeSearchParamByKey = useRemoveSearchParamByKey();
  const [scheduledDowngrade, setScheduledDowngrade] = useState<ScheduledDowngradeAttributes>();
  const [showRemoveBusinessWarmup, setShowRemoveBusinessWarmup] = useState<boolean>( false );
  const [showAcknowledgeScheduledDowngradeDialog, setShowAcknowledgeScheduledDowngradeDialog] = useState<boolean>( false );
  const [removeBusinessWarmupDialogContent, setRemoveBusinessWarmupDialogContent] = useState<ReactNode>();
  const [removeBusinessSuccessDialogContent, setRemoveBusinessSuccessDialogContent] = useState<ReactNode>();
  const [showRemoveBusinessSuccessDialog, setShowRemoveBusinessSuccessDialog] = useState<boolean>( false );
  const [showBillingPortalWarningDialog, setShowBillingPortalWarningDialog] = useState<boolean>( false );

  const cannotRemoveMoreBusinesses = scheduledDowngrade && scheduledDowngrade.new_quantity <= 1;

  useEffect( () =>
  {
    authenticationServices.refreshUser().then( ( data ) =>
    {
      handleUpsellDeeplink();
      handleReturnFromStripeCustomerPortal();
    } );
    stripeServices.activeSubscriptionInfo().then( ( subscriptionInfo ) =>
    {
      if ( subscriptionInfo.active_subscription?.scheduled_downgrade )
      {
        setScheduledDowngrade( subscriptionInfo.active_subscription.scheduled_downgrade )
      }
    } );
  }, [] );

  useEffect( () =>
  {
    setIsSubscribedFromAllNotificationsToggleOn( isSubscribedFromAllNotifications );
  }, [isSubscribedFromAllNotifications] );

  function handleReturnFromStripeCustomerPortal()
  {
    if ( returnFromStripe )
    {
      removeSearchParamByKey( ROUTE_PARAM.RETURN_FROM_STRIPE );
      dispatch( setApplicationLoadingState( false ) );
    }
  }

  function handleUpsellDeeplink()
  {
    if ( navigatedWithUpsellParam )
    {
      removeSearchParamByKey( ROUTE_PARAM.SETTINGS_TAB_UPSELL );

      if ( !isSubscribed )
      {
        dispatch( setUpsellShown( true ) );
      }
    }
  }

  function handleCreateLoginClicked()
  {
    setShowCreateLogin( !showCreateLogin );
  }

  function handleDialogOnClose()
  {
    setShowCreateLogin( false );
  }

  function handleCheckoutClicked()
  {
    if ( hasFinishedFirstRun )
    {
      dispatch( setUpsellShown( true ) )
      eventTracker.logUpsellShown( UPSELL_SOURCE_USER_SETTINGS );
    }
    else
    {
      dispatch( setAlertMessage( infoAlert( "Please complete your first post before subscribing." ) ) );
    }
  }

  async function handleClickOnCustomerPortal()
  {
    eventTracker.logManageSubscriptionClicked();

    dispatch( setApplicationLoadingState( true ) );
    const subscriptionInfo = await stripeServices.activeSubscriptionInfo();
    if ( subscriptionInfo.active_subscription?.scheduled_downgrade )
    {
      setScheduledDowngrade( subscriptionInfo.active_subscription.scheduled_downgrade );
      setShowBillingPortalWarningDialog( true );
      return;
    }
    stripeServices.customerPortalManageSubscription();
  }

  async function handleAddMultipleBusinesses()
  {
    dispatch( setApplicationLoadingState( true ) );
    const response = await businessServices.getAddBusinessesEligibility();
    if ( response.can_add_more_businesses && response.scheduled_downgrade )
    {
      setShowAcknowledgeScheduledDowngradeDialog( true );
    }
    else
    {
      if ( response.can_add_more_businesses && response.upgrade_required )
      {
        dispatch( setShowAddMultipleBusinessesUpsell( true ) );
      }
      else if ( response.can_add_more_businesses && !response.upgrade_required )
      {
        navigateWithSearchParams( ROUTES.ADD_BUSINESS_PURCHASE_COMPLETED );
      }
      else
      {
        dispatch( setAlertMessage( errorAlert( "You cannot add another business" ) ) );
      }
    }
    dispatch( setApplicationLoadingState( false ) );
  }

  function handleRemoveBusinessClicked()
  {
    dispatch( setApplicationLoadingState( true ) );
    eventTracker.logRemoveBusinessButtonClicked();
    getRemoveBusinessWarmupDialogContent().then( () =>
    {
      setShowRemoveBusinessWarmup( true );
    } ).finally( () =>
    {
      dispatch( setApplicationLoadingState( false ) );
    } );
  }

  function handleConfirmRemoveBusiness()
  {
    stripeServices.removeBusiness().then( ( scheduledDowngradeInfo ) =>
    {
      setScheduledDowngrade( scheduledDowngradeInfo.scheduled_downgrade );
      const dateString = DateUtils.getLongFormatDate( new Date( scheduledDowngradeInfo.scheduled_downgrade.next_phase_start_date ) );
      setRemoveBusinessSuccessDialogContent(
        <Typography sx={{ px: 10 }}>
          When the business is removed on <b>{dateString}</b>, you’ll be able to select the business(s) you want to stay active on your subscription.
        </Typography>
      )
      setShowRemoveBusinessSuccessDialog( true );
    } ).catch( ( error ) =>
    {
      dispatch( setAlertMessage( errorAlert( "We are having a problem processing the request. Please try again." ) ) );
    } ).finally( () =>
    {
      setShowRemoveBusinessWarmup( false );
    } )
  }

  function handleAcknowledgeRemoveBusinessSuccessDialog()
  {
    setShowRemoveBusinessSuccessDialog( false );
  }

  function handleCancelRemoveBusiness()
  {
    setShowRemoveBusinessWarmup( false );
  }

  function handleContactSupportClicked()
  {
    handleCloseAcknowledgeScheduledDowngradeDialog();
    window.open( ALKAI_FAQ_NEW_TICKET_URL, "_blank" );
  }

  function handleCloseAcknowledgeScheduledDowngradeDialog()
  {
    setShowAcknowledgeScheduledDowngradeDialog( false );
  }

  function handleBillingPortalWarningDialogContinue()
  {
    handleCloseBillingPortalWarningDialog();
    stripeServices.customerPortalManageSubscription();
  }

  function handleCloseBillingPortalWarningDialog()
  {
    dispatch( setApplicationLoadingState( false ) );
    setShowBillingPortalWarningDialog( false );
  }

  function handleNavigateToBranding()
  {
    eventTracker.logStyleAndBrandingClicked( businessId );
    navigateWithSearchParams( ROUTES.BRANDING );
  }

  function handleShowSocialMediaAccountManagement()
  {
    navigateWithSearchParams( ROUTES.SOCIAL_MEDIA_ACCOUNTS );
  }

  function handleShowPostingStrategyQuiz()
  {
    setShowPostingStrategyQuiz( true );
  }

  function closePostingStrategyQuiz()
  {
    setShowPostingStrategyQuiz( false );
  }

  function onSubscribeAllNotificationsChange( event: React.ChangeEvent<HTMLInputElement> )
  {
    const newValue = event.target.checked;
    setIsSubscribedFromAllNotificationsToggleOn( newValue );

    eventTracker.logUnsubscribedAllNotificationsChanged( !newValue );
    businessServices.updateUnsubscribedFromNotifications( !newValue );
  }

  async function getRemoveBusinessWarmupDialogContent()
  {
    const subscriptionInfo = await stripeServices.activeSubscriptionInfo();
    setScheduledDowngrade( subscriptionInfo.active_subscription?.scheduled_downgrade );
    const dateString = DateUtils.getLongFormatDate( new Date( subscriptionInfo.active_subscription?.current_period_end_date ) );

    setRemoveBusinessWarmupDialogContent(
      <Typography sx={{ px: 10 }}>
        <p>By proceeding, one business will be removed from your subscription at the end of the current subscription period
           on <b>{dateString}</b>.</p>
        <p>You’ll continue to have access to the business through the remainder of the current subscription period.</p>
      </Typography>
    )
  }

  function getFormattedMessageForContactSupportDialog(): React.ReactNode
  {
    return <Typography sx={{ px: 10 }}>
      <p>You have a business scheduled to be removed at the end of the current subscription period.</p>
      <p>Please contact support so we can best assist you with adding a new business or canceling the scheduled business removal.</p>
    </Typography>
  }

  function getFormattedMessageForBillingPortalWarningDialog(): React.ReactNode
  {
    return <Typography sx={{ px: 10 }}>
      <p>You have a business scheduled to be removed at the end of the current subscription period. During this time the billing portal will have
         limited functionality.</p>
      <p>Please <Link href={ALKAI_FAQ_NEW_TICKET_URL} target="_blank" rel="noopener">contact support</Link> if you need additional assistance during
         this time.</p>
    </Typography>
  }

  return <Stack spacing={5} sx={{ textAlign: "left", px: 5, py: 10 }}>
    <Helmet>
      <title>{ROUTE_SEO.USER_SETTINGS.title}</title>
      <meta name="description" content={ROUTE_SEO.USER_SETTINGS.description}/>
    </Helmet>

    {isGuest && <Button variant="outlined" onClick={handleCreateLoginClicked}>Create account</Button>}

    {
      userIsLoggedIn && hasFinishedFirstRun && <UserSettingsBusinessDetails/>
    }

    {
      shouldShowBrandStyle && <UserSettingsRow icon={<StoreOutlined/>}
                                               label="Brand kit"
                                               onClick={handleNavigateToBranding}/>
    }
    <SocialMediaAccountsSummary onClick={handleShowSocialMediaAccountManagement}/>
    <Divider/>
    <SurveyLink variant="subtitle1" surveyId={SURVEY_MONKEY_JUNE_2024_SURVEY_ID}/>
    <Divider/>
    {
      shouldShowCheckoutButton && <UserSettingsRow icon={<SellOutlined/>}
                                                   label="Subscribe now"
                                                   onClick={handleCheckoutClicked}/>
    }
    {
      shouldShowCustomerPortal && <Box>
                                 <UserSettingsRow icon={<ManageAccountsOutlinedIcon/>}
                                                  label="Manage subscription"
                                                  onClick={handleClickOnCustomerPortal}/>
                                 {wasSubscribed && !isSubscribed && <Typography sx={{ pl: "35px", textAlign: "left" }} variant="body2">Your subscription is no longer
                                                                                                                                       active.<br/> Please
                                                                                                                                       renew to continue using
                                                                                                                                       Alkai.</Typography>}
                               </Box>
    }


    {
      isSubscribed &&
      <UserSettingsRow icon={<AddBusinessOutlinedIcon/>}
                       label="Add a business"
                       onClick={handleAddMultipleBusinesses}/>
    }
    {
      hasMoreThanOneBusiness && !cannotRemoveMoreBusinesses &&
      <UserSettingsRow icon={<DoNotDisturbOnOutlined/>}
                       label="Remove business"
                       onClick={handleRemoveBusinessClicked}/>
    }
    <Divider/>
    <Typography variant="subtitle1">For Alkai tips & tricks:</Typography>
    <UserSettingsRow icon={<InstagramIcon sx={{ color: "black" }}/>}
                     label="Follow us on Instagram"
                     href={INSTAGRAM_ALKAI_URL}/>
    <UserSettingsRow icon={<FacebookIcon sx={{ color: "black" }}/>}
                     label="Check out our Facebook Page"
                     href={FACEBOOK_ALKAI_URL}/>
    <UserSettingsRow icon={<BookOutlinedIcon/>}
                     label="Read our blog"
                     href={ALKAI_BLOG_URL}/>
    <Divider/>
    {
      hasCommunicationChannel &&
      <NotificationSubscriptionToggle isToggleOn={isSubscribedFromAllNotificationsToggleOn} onChange={onSubscribeAllNotificationsChange}/>
    }
    <Divider/>
    <UserSettingsRow icon={<ImportantDevicesOutlinedIcon/>}
                     label="Get the app"
                     href={ALKAI_GET_THE_APP_URL}/>
    <UserSettingsRow icon={<HelpOutlineOutlinedIcon/>}
                     label="Help center"
                     href={ALKAI_FAQ_URL}/>
    <UserSettingsRow icon={<ChatOutlinedIcon/>}
                     label="Submit a help ticket"
                     href={ALKAI_FAQ_NEW_TICKET_URL}/>
    <Divider/>
    {userIsLoggedIn && <SignOutButton/>}

    <Stack direction="row"
           justifyContent="space-around">
      <Link sx={{ color: "black" }} href={TERMS_OF_USE_URL} rel="noopener" target="_blank">Terms of Use</Link>
      <Link sx={{ color: "black" }} href={PRIVACY_POLICY_URL} rel="noopener" target="_blank">Privacy Policy</Link>
    </Stack>
    <Divider/>
    {
      devModeEnabled && <Stack spacing={10} sx={{ textAlign: "left", background: "rgba(251, 231, 239, 0.8)" }}>
                       <Typography variant="h5">Dev mode section</Typography>

                       <UserSettingsRow icon={<HandymanOutlinedIcon/>}
                                        label="Show posting strategy quiz"
                                        onClick={handleShowPostingStrategyQuiz}/>
                       {isInternalUser && <NewBusinessButton/>}
                       {<ApptimizeVariablesLogView/>}
                       {<Typography variant="subtitle2">App Version: {ALKAI_VERSION}</Typography>}
                       {showPostingStrategyQuiz && <PostingStrategyQuizFullScreenDialog manageOpenStateExternally={true}
                                                                                        externalStateOpen={showPostingStrategyQuiz}
                                                                                        hideTopBar={true}
                                                                                        handleClose={closePostingStrategyQuiz}
                                                                                        modalDialogSxProps={{ maxWidth: "400px", mx: "auto" }}
                       />}
                     </Stack>
    }


    {showCreateLogin && <CreateAccountOrSignIn shownVia={CREATE_ACCOUNT_SHOWN_VIA_USER_SETTINGS}
                                               shouldShowSignIn={showCreateLogin}
                                               onClose={handleDialogOnClose}/>}
    <AlertBanner/>

    <ConfirmDialog title={"Want to remove a business from your subscription?"}
                   dialogContent={removeBusinessWarmupDialogContent}
                   open={showRemoveBusinessWarmup}
                   showNeedHelpLink={true}
                   confirmText={"Remove business"}
                   onConfirm={handleConfirmRemoveBusiness}
                   onClose={handleCancelRemoveBusiness}/>

    <ConfirmDialog title={"Your subscription is scheduled to change"}
                   dialogContent={removeBusinessSuccessDialogContent}
                   open={showRemoveBusinessSuccessDialog}
                   confirmText={"Ok"}
                   onConfirm={handleAcknowledgeRemoveBusinessSuccessDialog}
                   onClose={handleAcknowledgeRemoveBusinessSuccessDialog}/>

    <ConfirmDialog title={"Please contact support"}
                   dialogContent={getFormattedMessageForContactSupportDialog()}
                   open={showAcknowledgeScheduledDowngradeDialog}
                   confirmText={"Contact support"}
                   onConfirm={handleContactSupportClicked}
                   onClose={handleCloseAcknowledgeScheduledDowngradeDialog}/>

    <ConfirmDialog title={"Billing portal update"}
                   dialogContent={getFormattedMessageForBillingPortalWarningDialog()}
                   open={showBillingPortalWarningDialog}
                   confirmText={"Continue"}
                   onConfirm={handleBillingPortalWarningDialogContinue}
                   onClose={handleCloseBillingPortalWarningDialog}/>


  </Stack>
}
