import { createSlice } from "@reduxjs/toolkit";
import { ChatMessage } from "../../services/chat.services";
import { RootState } from "../../app/store";
import { isEmpty, map, maxBy, remove } from "lodash";
import {
  MediaAssetSource,
  MESSAGE_ROLE_USER,
  MESSAGE_TYPE_FREQUENCY_REQUEST,
  MESSAGE_TYPE_MULTI_TOPICS_APPROVAL,
  MESSAGE_TYPE_MULTIPLE_POST_IDEAS,
  MESSAGE_TYPE_TEXT_REQUEST,
  QUICK_ACTIONS_WAIT_TIME_MS,
  USER_FEEDBACK_STATUS_CANCELED,
  USER_FEEDBACK_STATUS_SHOWN,
  USER_FEEDBACK_STATUS_SUBMITTED
} from "../constants";
import { RecordingStatusAPI } from "../../services/postIdeaServices";
import { ALKAI_CORE_ASSISTANT_TYPE } from "../../constants";
import { BrandStyleAPI } from "../../services/business.services";
import { getFirstPostDrafted } from "../business/businessSlice";

export type MessageAPI = {
  id: string
  text?: string
  type: string
  role?: string
  timestamp: string
  completed?: boolean
  data?: TopicIdeaDataAPI | PostIdeaDataAPI | MultiTopicsDataAPI | PostChoiceDataAPI | PostIdeaDataAPI[];
  showAvatar?: boolean
  image_url?: string
}

export type TopicIdeaDataAPI = {
  id: string
  name: string
  source: string
  approval?: boolean
}

export type MultiTopicsDataAPI = {
  topics: TopicIdeaDataAPI[]
  approved?: string[]
}

export type PostChoiceDataAPI = {
  choice?: string
}

export interface FontSetTrackingData
{
  owned_by_business: boolean,
  custom_font_count: number
}

export interface PostIdeaTrackingData
{
  font_set: FontSetTrackingData;
}

export interface PostIdeaContextType
{
  postIdea: PostIdeaDataAPI
}

export type PostIdeaDataAPI = {
  headline: string
  headline_search_term: string
  caption: string
  slides_json: SlideAPI[]
  media_description: string
  configuration: ConfigurationAPI
  post_idea_formula: PostIdeaFormulaAPI
  url: string
  id: string
  topic_name?: string
  topic: TopicIdeaDataAPI
  edited_at?: string
  downloaded_at?: string
  first_completed_at?: string
  user_feedback?: UserFeedbackAPI
  recording: RecordingAPI
  custom_media_style?: string
  post_idea_recipe?: PostIdeaRecipeAPI
  tracking_data?: PostIdeaTrackingData;
  has_active_placeholder_media?: boolean;
  output_format_slug?: string;
}

export type PostIdeaRecipeAPI = {
  id: string;
}

export type RecordingAPI = {
  id: string;
  video_url: string;
  image_url: string;
  recording_status: RecordingStatusAPI;
}

export type UserFeedbackStatus = typeof USER_FEEDBACK_STATUS_SHOWN | typeof USER_FEEDBACK_STATUS_SUBMITTED | typeof USER_FEEDBACK_STATUS_CANCELED

export type UserFeedbackAPI = {
  content?: string;
  text_rating?: string;
  visual_rating?: string;
  overall_rating?: string;
  status?: UserFeedbackStatus;
}

export type TemplateInputData = {
  mediaList: MediaItem[]
}

export type MediaItem = {
  media_url: string
  is_video: boolean
}

export type SlideAPI = {
  bullet: string
  stock_media_search_term: string
}

export type ConfigurationAPI = {
  global_text: []
  config_bundle: ConfigBundleAPI
  slides: []
  search_terms: ConfigurationSearchTermAPI[]
}

export type ConfigBundleAPI = {
  color_pair: ColorPairAPI
  color_palette_slug: string
  font_set_slug: string
  font_pair: FontPairAPI
  preset_name: string
  brand_style?: BrandStyleAPI
  beat_sync_rules?: [],
  slide_configs: SlideConfigAPI[],
  holiday?: HolidayLayoutData,
}

export type HolidayLayoutData = {
  holiday_layout_slug: string
  holiday_name: string
}

export type ColorPairAPI = {
  color_1: string
  color_2: string
}

export type FontPairAPI = {
  primary: string
  secondary: string
}

export type SlideConfigAPI = {
  type: string
}

export type PostIdeaFormulaAPI = {
  name: string
  slug: string
}

export interface ConfigurationSearchTermOptionsAPI
{
  descriptionOfScene: string;
  terms: string[];
  fallback: string;
  background: string;
}

export interface ConfigurationSearchTermAPI
{
  id: number;
  selected_search_term: string;
  search_options: ConfigurationSearchTermOptionsAPI;
}

export interface MediaSelection
{
  id: string;
  url: string;
  isVideo: boolean;
  source: MediaAssetSource;
}

export interface AssistantChatState
{
  waitingForThreadRunToComplete?: boolean,
  messages: ChatMessage[],
  postIdeaMessages: MessageAPI[],
  showChooseMediaButton?: boolean,
  selectedMedia?: [],
  checkMessagesFailedAllAttempts?: boolean,
  ensureChatThread?: boolean,
  assistantType?: string,
  waitingType?: string,
}

const initialState: AssistantChatState = {
  waitingForThreadRunToComplete: false,
  messages: [],
  postIdeaMessages: [],
  checkMessagesFailedAllAttempts: false,
  ensureChatThread: false,
  assistantType: undefined,
}

export const assistantChatSlice = createSlice( {
  name: 'assistantChat',
  initialState,
  reducers: {
    setWaitingForThreadRunToComplete: ( state, action ) =>
    {
      return {
        ...state,
        waitingForThreadRunToComplete: action.payload
      }
    },
    setMessages: ( state, action ) =>
    {
      return {
        ...state,
        messages: action.payload
      }
    },
    setAssistantType: ( state, action ) =>
    {
      return {
        ...state,
        assistantType: action.payload
      }
    },
    setPostIdeaMessages: ( state, action ) =>
    {
      return {
        ...state,
        postIdeaMessages: action.payload
      }
    },
    addPostIdeaMessage: ( state, action ) =>
    {
      const previousPostIdeaMessages = state.postIdeaMessages || [];
      const copyOfPreviousMessages = [...previousPostIdeaMessages];
      remove( copyOfPreviousMessages, ( message ) =>
      {
        return message.id === action.payload.id;
      } )

      return {
        ...state,
        postIdeaMessages: [...copyOfPreviousMessages, action.payload]
      }
    },
    updateChatPostIdeaMessage: ( state, action ) =>
    {
      const { data, post_idea_id, ...otherMessageFields } = action.payload;
      const newMessages = map( state.postIdeaMessages, ( message ) =>
      {
        if ( message.id === otherMessageFields.id )
        {
          if ( message.type === MESSAGE_TYPE_MULTIPLE_POST_IDEAS )
          {
            const currentData = message.data as PostIdeaDataAPI[];
            const updatedData = map( currentData, ( postIdeaData ) =>
            {
              if ( postIdeaData.id === post_idea_id )
              {
                return {
                  ...postIdeaData,
                  ...data
                }
              }
              else
              {
                return postIdeaData;
              }
            } )

            return {
              ...message,
              data: updatedData
            };
          }
          else
          {
            return {
              ...message,
              ...otherMessageFields,
              data: {
                ...message.data,
                ...data,
              }
            };
          }
        }
        return message;
      } );

      return {
        ...state,
        postIdeaMessages: [...newMessages]
      }
    },
    setShowChooseMediaButton: ( state, action ) =>
    {
      return {
        ...state,
        showChooseMediaButton: action.payload
      }
    },
    setSelectedMedia: ( state, action ) =>
    {
      return {
        ...state,
        selectedMedia: action.payload
      }
    },
    setCheckMessagesFailedAllAttempts: ( state, action ) =>
    {
      return {
        ...state,
        checkMessagesFailedAllAttempts: action.payload,
      }
    },
    setEnsureChatThread: ( state, action ) =>
    {
      return {
        ...state,
        ensureChatThread: action.payload,
      }
    },
    clearAssistantChat: () =>
    {
      return {
        ...initialState
      }
    },
    setAssistantWaitingType: ( state, action ) =>
    {
      return {
        ...state,
        waitingType: action.payload
      }
    },
    clearAssistantWaitingType: ( state ) =>
    {
      return {
        ...state,
        waitingType: undefined
      }
    },
  }
} )

export const getMessages = ( state: RootState ) =>
{
  return state.assistantChat.messages;
}

export const getAssistantType = ( state: RootState ) =>
{
  return state.assistantChat.assistantType;
}

export const showChooseMediaButton = ( state: RootState ) =>
{
  return state.assistantChat.showChooseMediaButton;
}

function getNewestMessage( state: RootState )
{
  return maxBy( state.assistantChat.messages, ( message ) =>
  {
    return message.created_at
  } )
}

export const showQuickActions = ( state: RootState ) =>
{
  const assistantType = getAssistantType( state );
  const lastMessage = getNewestMessage( state );

  if ( !!assistantType && !!lastMessage )
  {
    const isCoreAssistant = assistantType === ALKAI_CORE_ASSISTANT_TYPE
    const lastMessageTimeMS = lastMessage.created_at * 1000;
    const currentTimeMS = Date.now();
    const twoMinutesInMS = QUICK_ACTIONS_WAIT_TIME_MS;
    return isCoreAssistant && lastMessageTimeMS + twoMinutesInMS < currentTimeMS;
  }
  return false;
}

export const showOnboardingQuickActions = ( state: RootState ) =>
{
  const isOnboardingInProgress = !getFirstPostDrafted( state );
  const userHasNotSentAnyMessages = !state.assistantChat.messages.find( ( message ) => message.role === MESSAGE_ROLE_USER );

  return isOnboardingInProgress && userHasNotSentAnyMessages;
}

export const getPostIdeaMessages = ( state: RootState ) =>
{
  return state.assistantChat.postIdeaMessages;
}

export const hasPostIdeaMessages = ( state: RootState ) =>
{
  return !!state.assistantChat.postIdeaMessages && state.assistantChat.postIdeaMessages.length > 0;
}

export const getLatestPostIdeaMessageTimestamp = ( state: RootState ) =>
{
  if ( !!state.assistantChat.postIdeaMessages )
  {
    const latestMessage = maxBy( state.assistantChat.postIdeaMessages, ( message ) =>
    {
      return message.timestamp
    } )

    return latestMessage?.timestamp || 0;
  }

  return 0;
}

export const isUserMessage = ( message: MessageAPI | undefined ) =>
{
  if ( !message )
  {
    return false;
  }

  const isUserRole = message.role === MESSAGE_ROLE_USER;
  const finishedTextRequest = message.type === MESSAGE_TYPE_TEXT_REQUEST && !isEmpty( message.text );
  const finishedNumberRequest = message.type === MESSAGE_TYPE_FREQUENCY_REQUEST && !isEmpty( message.text );
  const finishedMultiTopicRequest = message.type === MESSAGE_TYPE_MULTI_TOPICS_APPROVAL && message.completed;

  return isUserRole || finishedTextRequest || finishedNumberRequest || finishedMultiTopicRequest;
}

export const {
  setWaitingForThreadRunToComplete,
  setMessages,
  setAssistantType,
  setPostIdeaMessages,
  addPostIdeaMessage,
  updateChatPostIdeaMessage,
  setShowChooseMediaButton,
  setSelectedMedia,
  clearAssistantChat,
  setCheckMessagesFailedAllAttempts,
  setEnsureChatThread,
  setAssistantWaitingType,
  clearAssistantWaitingType,
} = assistantChatSlice.actions

export default assistantChatSlice.reducer
