import { businessServices, FACEBOOK_INSTAGRAM, FACEBOOK_PAGE, SocialNetworkAccountAPI } from "../../services/business.services";
import React, { useContext } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { SocialAccountProfileIcon } from "../ui/socialAccountProfileIcon";
import { SwitchSocialNetworkAccountButton } from "./SwitchSocialNetworkAccountButton";
import { ConfirmFacebookPageAccount } from "../confirmSocialNetworkAccount/confirmFacebookPageAccount";
import { ConfirmInstagramAccount } from "../confirmSocialNetworkAccount/confirmInstagramAccount";
import { socialNetworkAccountHelper } from "../../helpers/socialNetworkAccountHelper";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { RefreshFacebookButton } from "../buttons/refreshFacebookButton";
import { size } from "lodash";

export interface SocialMediaAccountRowProps
{
  socialNetworkAccount: SocialNetworkAccountAPI;
  otherSocialNetworksToSwitchTo?: SocialNetworkAccountAPI[];
  fromSettings?: boolean;
}

export function SocialMediaAccountRow( props: SocialMediaAccountRowProps )
{
  const needsRefresh = socialNetworkAccountHelper.needsRefresh( props.socialNetworkAccount );
  const socialNetworkAccountsContext = useContext( SocialNetworkAccountsContext );

  function shouldShowSwitchButton()
  {
    return size( props.otherSocialNetworksToSwitchTo ) > 1;
  }

  function reloadSocialNetworkAccounts()
  {
    businessServices.listSocialNetworkAccounts().then( ( socialNetworkAccountsResponseAPI ) =>
      {
        socialNetworkAccountsContext.updateSocialNetworkAccounts( socialNetworkAccountsResponseAPI.social_network_accounts );
      }
    );
  }

  function showSwitchButton()
  {
    if ( props.socialNetworkAccount.account_type === FACEBOOK_INSTAGRAM )
    {
      return <SwitchSocialNetworkAccountButton component={ConfirmInstagramAccount}
                                               onDrawerClosed={reloadSocialNetworkAccounts}
                                               fromSettings={props.fromSettings}
      />
    }
    else if ( props.socialNetworkAccount.account_type === FACEBOOK_PAGE )
    {
      return <SwitchSocialNetworkAccountButton component={ConfirmFacebookPageAccount}
                                               onDrawerClosed={reloadSocialNetworkAccounts}
                                               fromSettings={props.fromSettings}
      />
    }
  }

  return (<Stack
    sx={{ mt: 10, display: "flex", flexDirection: "row", gap: "10px", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
    <Stack sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
      <SocialAccountProfileIcon profileImageUrl={props.socialNetworkAccount.profile_image_url}
                                accountType={props.socialNetworkAccount.account_type}/>
      <Typography>{props.socialNetworkAccount.name}</Typography>
    </Stack>
    <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
      {needsRefresh && <RefreshFacebookButton handleRefreshComplete={reloadSocialNetworkAccounts}
                                              iconOnly={true}/>}
      {shouldShowSwitchButton() && showSwitchButton()}
    </Box>
  </Stack>);
}