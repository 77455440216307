import { postJsonToApi, postToApi } from "./requestManager";
import { businessServices, MediaAssetAPI, MediaAssetTrackingDataAPI } from "./business.services";
import { MEDIA_ASSET_FILE_TYPE_PHOTO, MEDIA_ASSET_FILE_TYPE_UNSUPPORTED, MEDIA_ASSET_FILE_TYPE_VIDEO, MediaAssetSource } from "../features/constants";
import { concat, indexOf, last, map } from "lodash";
import { store } from "../app/store";
import { clearProgressState, setProgressState } from "../features/ui/uiSlice";
import { progressTracker } from "../helpers/progressTracker";
import { RequestPresignedUploadAPIResponse, s3UploadServices } from "./s3Upload.services";
import { errorReporter } from "../features/error/errorReporter";
import { currentUserBusinessId } from "../features/business/businessSlice";

export const mediaAssetServices = {
  uploadAssets,
}

type ConvertVideoState = "receiving_file" | "converting" | "trimming" | "s3_uploading";

export const VIDEO_CONVERSION_RECEIVING_FILE: ConvertVideoState = "receiving_file";
export const VIDEO_CONVERTING: ConvertVideoState = "converting";
export const VIDEO_TRIMMING: ConvertVideoState = "trimming";
export const VIDEO_CONVERSION_S3_UPLOADING: ConvertVideoState = "s3_uploading";

export const VIDEO_CONVERSION_PROGRESS_STATES: ConvertVideoState[] = [
  VIDEO_CONVERSION_RECEIVING_FILE,
  VIDEO_CONVERTING,
  VIDEO_TRIMMING,
  VIDEO_CONVERSION_S3_UPLOADING,
];

interface RequestVideoConversionAPIResponse
{
  url: string;
  auth_token: string;
}

export interface MediaAssetData
{
  fileName: string;
  file: Blob;
  fileSize?: number;
  fileType: typeof MEDIA_ASSET_FILE_TYPE_PHOTO | typeof MEDIA_ASSET_FILE_TYPE_VIDEO | typeof MEDIA_ASSET_FILE_TYPE_UNSUPPORTED;
  isLogo?: boolean;
  trackingData?: MediaAssetTrackingDataAPI;
}

interface ConvertVideoAPIUpdate
{
  state: ConvertVideoState;
  progress?: number;
  combinedProgress?: number;
}

const MEDIA_ASSETS_URL_PREFIX = "media_assets";
const REQUEST_PRESIGNED_UPLOAD_PATH = "request_presigned_upload";
const REQUEST_VIDEO_CONVERSION_UPLOAD_URL = "request_video_conversion_upload";

async function uploadAssets( listOfMediaAssetData: MediaAssetData[], mediaAssetSource: MediaAssetSource ): Promise<MediaAssetAPI[]>
{
  try
  {
    const fileNames = map( listOfMediaAssetData, ( data ) => data.fileName );
    const fileUploadProgressContext = progressTracker.createProgressContext( fileNames );

    store.dispatch( clearProgressState() );

    if ( listOfMediaAssetData.length > 1 )
    {
      store.dispatch( setProgressState( { message: "Processing " + listOfMediaAssetData.length + " media files" } ) );
    }
    else
    {
      store.dispatch( setProgressState( { message: "Processing " + listOfMediaAssetData.length + " media file" } ) );
    }

    const result = await Promise.all(
      listOfMediaAssetData.map( async ( mediaAssetData ) =>
      {
        const queryParams = {
          file_name: mediaAssetData.fileName,
        }

        if ( mediaAssetData.fileType === MEDIA_ASSET_FILE_TYPE_PHOTO )
        {
          const serviceUrl = buildMediaAssetUrl( [REQUEST_PRESIGNED_UPLOAD_PATH] );
          const presignedUpload: RequestPresignedUploadAPIResponse = await postToApi( serviceUrl, queryParams );
          progressTracker.updateProgress( fileUploadProgressContext, mediaAssetData.fileName, 0 );
          const response = await s3UploadServices.uploadFileToS3( presignedUpload.url, presignedUpload.fields, mediaAssetData.file );

          progressTracker.updateProgress( fileUploadProgressContext, mediaAssetData.fileName, 100 );
          if ( response.ok )
          {
            return await businessServices.addMediaAssetToLibrary( presignedUpload.s3_direct_url, mediaAssetSource, mediaAssetData.isLogo,
              mediaAssetData.trackingData );
          }
          else
          {
            errorReporter.reportErrorToSentry( new Error( "Failed to upload media file to S3" ), {
              response: response.status,
              responseStatusText: response.statusText,
              fileName: mediaAssetData.fileName,
            } );
            return Promise.reject( "Failed to upload media file to S3" );
          }
        }
        else if ( mediaAssetData.fileType === MEDIA_ASSET_FILE_TYPE_VIDEO )
        {
          const conversionServiceUrl = buildMediaAssetUrl( [REQUEST_VIDEO_CONVERSION_UPLOAD_URL] );
          const currentBusinessId = currentUserBusinessId( store.getState() );
          const response: RequestVideoConversionAPIResponse = await postJsonToApi( conversionServiceUrl, {}, { business_id: currentBusinessId } );
          const uploadResult = await convertAndUploadVideo( response.url, response.auth_token, mediaAssetData, fileUploadProgressContext );
          const s3DirectUrl = uploadResult.s3_direct_url;
          const mergedTrackingData = {
            ...mediaAssetData.trackingData,
            width: uploadResult.metadata.width,
            height: uploadResult.metadata.height,
            video_duration: uploadResult.metadata.duration,
          }
          return await businessServices.addMediaAssetToLibrary( s3DirectUrl, mediaAssetSource, false, mergedTrackingData );
        }
        else
        {
          return Promise.reject( "Invalid asset file type: " + mediaAssetData.fileType );
        }
      } )
    );
    store.dispatch( clearProgressState() );
    return result;
  }
  catch (error)
  {
    store.dispatch( clearProgressState() );
    return Promise.reject( error );
  }
}

function buildMediaAssetUrl( pathPieces: string[] )
{
  return concat( [MEDIA_ASSETS_URL_PREFIX], pathPieces ).join( "/" );
}

async function convertAndUploadVideo( conversionPresignedUrl: string, authToken: string, mediaAssetData: MediaAssetData,
                                      progressContext: any ): Promise<any>
{
  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/octet-stream",
    "Authorization": authToken,
  };

  const response = await fetch( conversionPresignedUrl, {
    headers: headers,
    method: "POST",
    body: mediaAssetData.file
  } )

  const bodyReader = response.body?.getReader();
  const decoder = new TextDecoder( "utf-8" );
  let partialJson: string | undefined = "";
  while ( !!bodyReader )
  {
    const { done, value } = await bodyReader.read();
    partialJson += decoder.decode( value || new Uint8Array( 0 ), {
      stream: !done,
    } );

    const completeObjects = partialJson && partialJson.split( "\n" );
    if ( !done )
    {
      partialJson = completeObjects.pop();
      if ( completeObjects.length > 0 )
      {
        const mostRecentProgress = completeObjects.pop();

        if ( !!mostRecentProgress )
        {
          const mostRecentProgressJson: ConvertVideoAPIUpdate = JSON.parse( mostRecentProgress );
          const convertCombinedProgress = calculateCombinedProgress( VIDEO_CONVERSION_PROGRESS_STATES, mostRecentProgressJson )
          progressTracker.updateProgress( progressContext, mediaAssetData.fileName, convertCombinedProgress );
        }
      }
    }
    if ( done )
    {
      let lastObject = last( completeObjects );
      if ( !!lastObject )
      {
        if ( typeof lastObject === "string" )
        {
          return JSON.parse( lastObject );
        }
      }
    }
  }
}

function calculateCombinedProgress( progressStates: ConvertVideoState[], currentProgress: ConvertVideoAPIUpdate )
{
  if ( progressStates[0] === currentProgress.state || currentProgress.progress === undefined )
  {
    return 0;
  }
  const aggregate = indexOf( progressStates, currentProgress.state );
  const combinedProgress = (currentProgress.progress / progressStates.length) + (aggregate / progressStates.length);
  return Math.min( Math.round( combinedProgress * 100 ), 100 );
}
