import React, { useState } from 'react'
import { SignIn } from "./signIn";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../app/store";
import { getUserSlug, isGuestUser, isUserLoggedInAndVerified, isUserVerificationRequired, updateUserVerificationRequired } from "../user/userSlice";
import { CREATE_ACCOUNT_SHOWN_VIA_USER_SETTINGS, SIGN_IN_PROVIDER_EMAIL, SIGN_IN_PROVIDER_PHONE_NUMBER, SignInProviderType } from "../constants";
import { VerifySignInInput } from "./verifySignInInput";
import { eventTracker } from "../../helpers/eventTracker";
import { ExistingUserChoice, ExistingUserChoiceDialog, KEEP_CURRENT_USER, KEEP_EXISTING_USER, REMAIN_GUEST_USER } from "./existingUserChoiceDialog";
import { authenticationServices, UserApiData } from "../../services/authentication.services";
import ProgressOverlay from "../loadingIndicator/progressOverlay";
import { infoAlert, setAlertMessage, warningAlert } from "../alert/alertSlice";
import { getVerificationFailureMessage, getVerificationFailureMessageForExistingUserChoice } from "../utils/errorMessageUtils";
import { userHelper } from "../user/userHelper";

export interface VerifyAndSignInData
{
  verificationCode: string;
  signInProvider: string;
  inputValue: string;
}

export interface SignInAndVerifyProps
{
  onSignInVerified: () => void;
  onSignInClose: () => void;
  source: string;
  shownVia: string;
  signInAsGuestInProgress: boolean;
  initialSignInProvider: SignInProviderType;
}

export function SignInAndVerify( props: SignInAndVerifyProps )
{
  const dispatch = useDispatch();
  const [signInProvider, setSignInProvider] = useState( props.initialSignInProvider );
  const [signInInputValue, setSignInInputValue] = useState<string>( "" );
  const userVerificationRequired = useSelector( ( state: RootState ) => isUserVerificationRequired( state ) );
  const [userCompletedVerification, setUserCompletedVerification] = useState<boolean>( false );
  const userIsLoggedInAndVerified = useSelector( ( state: RootState ) => isUserLoggedInAndVerified( state ) );
  const isGuest = useSelector( ( state: RootState ) => isGuestUser( state ) );
  const isShownViaUserSettings = props.shownVia === CREATE_ACCOUNT_SHOWN_VIA_USER_SETTINGS;

  const missingSignInInput = signInInputValue === "";

  const shouldShowSignInInput = (!userIsLoggedInAndVerified && !userVerificationRequired)
                                || (!userIsLoggedInAndVerified && userVerificationRequired && missingSignInInput)
                                || (isGuest && !userVerificationRequired)
                                || (isGuest && userVerificationRequired && missingSignInInput);

  const shouldShowVerifyInput = (userVerificationRequired || !userCompletedVerification) && !missingSignInInput;
  const [existingUserData, setExistingUserData] = useState<UserApiData | undefined>( undefined );
  const [currentUserData, setCurrentUserData] = useState<UserApiData | undefined>( undefined );
  const [verifyAndSignInData, setVerifyAndSignInData] = useState<VerifyAndSignInData | undefined>( undefined );
  const [showExistingUserChoiceDialog, setShowExistingUserChoiceDialog] = useState<boolean>( false );
  const [showLoadingIndicator, setShowLoadingIndicator] = useState<boolean>( false );

  const onUserInputChanged = ( newInputValue: string ) =>
  {
    setSignInInputValue( newInputValue )
  }

  const onSignInVerified = () =>
  {
    setUserCompletedVerification( true );
    eventTracker.logUserSignInSucceeded();
    props.onSignInVerified();
  }

  const handleCloseExistingUserChoiceDialog = ( choice: ExistingUserChoice ) =>
  {
    setShowLoadingIndicator( true );
    setShowExistingUserChoiceDialog( false );
    if ( choice === KEEP_EXISTING_USER )
    {
      const guestUserId = getUserSlug( store.getState() );
      authenticationServices.signOut().then( () =>
      {
        userHelper.signOutUser( dispatch );
        if ( !!verifyAndSignInData )
        {
          authenticationServices.verifyAndSignIn( verifyAndSignInData.inputValue, verifyAndSignInData.signInProvider,
            verifyAndSignInData.verificationCode, choice ).then( async ( response ) =>
          {
            if ( response.data )
            {
              onSignInVerified();
              eventTracker.logExistingUserRestored( guestUserId );
              store.dispatch( setAlertMessage( infoAlert( "You have been signed in successfully!", 'top', true ) ) );
            }
            else
            {
              store.dispatch( updateUserVerificationRequired( null ) )
              store.dispatch( setAlertMessage( warningAlert(
                getVerificationFailureMessageForExistingUserChoice( verifyAndSignInData.signInProvider, verifyAndSignInData.inputValue ) ) ) );
            }
          } ).catch( () =>
          {
            store.dispatch( setAlertMessage( warningAlert( getVerificationFailureMessage( verifyAndSignInData.signInProvider ) ) ) );
          } ).finally( () =>
          {
            setShowLoadingIndicator( false );
          } );
        }
      } ).catch( () =>
      {
        store.dispatch( setAlertMessage( warningAlert( "There was a problem processing your request. Please try again." ) ) );
        setShowLoadingIndicator( false );
      } );
    }
    else if ( choice === KEEP_CURRENT_USER )
    {
      if ( !!verifyAndSignInData )
      {
        authenticationServices.verifyAndSignIn( verifyAndSignInData.inputValue, verifyAndSignInData.signInProvider,
          verifyAndSignInData.verificationCode, choice ).then( async ( response ) =>
        {
          onSignInVerified();
          store.dispatch( setAlertMessage( infoAlert( "Account created successfully!", 'top', true ) ) );
        } ).catch( () =>
        {
          store.dispatch( setAlertMessage( warningAlert( getVerificationFailureMessage( verifyAndSignInData.signInProvider ) ) ) );
        } ).finally( () =>
        {
          setShowLoadingIndicator( false );
        } );
      }
    }
    else if ( choice === REMAIN_GUEST_USER )
    {
      setUserCompletedVerification( false );
      setShowLoadingIndicator( false );
      props.onSignInClose();
    }
  }

  const onExistingUserFound = ( existingUserData: UserApiData, currentUserData: UserApiData, verifyAndSignInData: VerifyAndSignInData ) =>
  {
    setExistingUserData( existingUserData );
    setCurrentUserData( currentUserData );
    setVerifyAndSignInData( verifyAndSignInData );
    setShowExistingUserChoiceDialog( true );
    setUserCompletedVerification( false );
  }

  const onVerifySignInInputCancelled = () =>
  {
    setUserCompletedVerification( false );
    setSignInInputValue( "" );
  }

  const onSignInProviderChangeRequested = () =>
  {
    setSignInProvider( ( prevState ) => prevState === SIGN_IN_PROVIDER_PHONE_NUMBER ? SIGN_IN_PROVIDER_EMAIL : SIGN_IN_PROVIDER_PHONE_NUMBER );
  }

  return <>
    {shouldShowSignInInput && <SignIn source={props.source}
                                      shownVia={props.shownVia}
                                      onUserInputChanged={onUserInputChanged}
                                      onSignInProviderChangeRequested={onSignInProviderChangeRequested}
                                      signInProvider={signInProvider}
                                      onSignInClose={props.onSignInClose}
                                      signInAsGuestInProgress={props.signInAsGuestInProgress}/>}
    {shouldShowVerifyInput && <VerifySignInInput inputValue={signInInputValue}
                                                 signInProvider={signInProvider}
                                                 isUserSettings={isShownViaUserSettings}
                                                 onSignInVerified={onSignInVerified}
                                                 onExistingUserFound={onExistingUserFound}
                                                 onCancelled={onVerifySignInInputCancelled}
    />}

    {showExistingUserChoiceDialog && <ExistingUserChoiceDialog currentUserData={currentUserData}
                                                               existingUserData={existingUserData}
                                                               openExistingUserChoiceDialog={showExistingUserChoiceDialog}
                                                               closeExistingUserChoiceDialog={handleCloseExistingUserChoiceDialog}/>}

    {showLoadingIndicator && <ProgressOverlay/>}
  </>

}
