import { FullPageMobileContainer } from "../layout/fullPageMobileContainer";
import withFullScreenDialog, { WithFullScreenDialogProps } from "../ui/withFullScreenDialog";
import * as React from "react";
import { useEffect, useState } from "react";
import { ALKAI_FAQ_URL, ROUTES, UPSELL_GRADIENT } from "../constants";
import { businessServices, RefreshBusinessAPI } from "../../services/business.services";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { Checkbox, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from "@mui/material";
import { head, includes, isEmpty, map, size, take } from "lodash";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import AlertBanner from "../alert/alertBanner";
import { BusinessAvatar } from "../businessSwitcher/businessAvatar";
import { getBusinessSlug } from "../business/businessSlice";
import { RootState } from "../../app/store";
import { userHelper } from "../user/userHelper";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import { businessHelper } from "../../helpers/businessHelper";
import { authenticationServices } from "../../services/authentication.services";
import { eventTracker } from "../../helpers/eventTracker";

export const SUBSCRIPTION_DIALOGS_TITLE_COLOR = "#464DE1";

function SelectBusinessesDialogContent( props: WithFullScreenDialogProps )
{
  const dispatch = useDispatch();
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const [businesses, setBusinesses] = useState<RefreshBusinessAPI[]>( [] )
  const [businessSlugsToEnable, setBusinessSlugsToEnable] = React.useState<string[]>( [] );
  const [maximumNumberOfBusinessesToSelect, setMaximumNumberOfBusinessesToSelect] = React.useState( 1 )
  const currentBusinessSlug = useSelector( ( state: RootState ) => getBusinessSlug( state ) );
  const [confirmButtonLoading, setConfirmButtonLoading] = useState( false );

  useEffect( () =>
  {
    onMount()
  }, [] );

  const handleToggle = ( value: string ) => () =>
  {
    const currentIndex = businessSlugsToEnable.indexOf( value );

    const newChecked = [...businessSlugsToEnable];
    if ( currentIndex === -1 )
    {
      if ( size( businessSlugsToEnable ) >= maximumNumberOfBusinessesToSelect )
      {
        dispatch( setAlertMessage(
          errorAlert( `You have a limit of ${maximumNumberOfBusinessesToSelect} businesses. Please deselect one to select another`, "top" ) ) );
        return;
      }
      newChecked.push( value );
    }
    else
    {
      newChecked.splice( currentIndex, 1 );
    }

    setBusinessSlugsToEnable( newChecked );
  };

  async function onMount()
  {
    const eligibilityResponse = await businessServices.getSelectBusinessesEligibility()
    if ( eligibilityResponse.should_force_select_businesses )
    {
      eventTracker.logDowngradeBusinessesShown();
      const businessesResponse = await businessServices.listBusinesses();
      setBusinesses( businessesResponse.businesses )
      setMaximumNumberOfBusinessesToSelect( eligibilityResponse.max_number_of_businesses )
      const preselectedBusinesses = take( businessesResponse.businesses, eligibilityResponse.max_number_of_businesses )
      const preselectedBusinessSlugs = map( preselectedBusinesses, ( business ) =>
      {
        return business.slug
      } )
      setBusinessSlugsToEnable( preselectedBusinessSlugs );
    }
    else
    {
      navigateWithSearchParams( ROUTES.CHAT );
    }
  }

  function shouldEnable()
  {
    return size( businessSlugsToEnable ) === maximumNumberOfBusinessesToSelect;
  }

  async function handleClick()
  {
    try
    {
      setConfirmButtonLoading( true );
      await businessServices.setEnabledBusinesses( businessSlugsToEnable );
      eventTracker.logStripeDowngradeComplete()
      if ( includes( businessSlugsToEnable, currentBusinessSlug ) )
      {
        await authenticationServices.refreshUser();
        navigateWithSearchParams( ROUTES.CHAT );
      }
      else
      {
        const businessSlug = head( businessSlugsToEnable );
        if ( businessSlug )
        {
          await userHelper.switchBusinesses( businessSlug, dispatch );
          navigateWithSearchParams( ROUTES.CHAT );
        }
      }
    }
    catch (e)
    {
      dispatch( setAlertMessage( errorAlert( "Please try again or contact support" ) ) );
    }
    finally
    {
      setConfirmButtonLoading( false );
    }
  }

  return (
    <FullPageMobileContainer sx={{ background: UPSELL_GRADIENT }}>
      <Stack spacing={5} sx={{ display: "flex", alignItems: "center", my: 12, mx: "auto", maxWidth: 360, bgcolor: 'background.paper', p: 10 }}>
        <Typography sx={{ textAlign: "center", color: SUBSCRIPTION_DIALOGS_TITLE_COLOR, fontFamily: "Montserrat", fontWeight: 700, lineHeight: "24px", fontSize: 22 }}>Your
                                                                                                                                            subscription<br/>
                                                                                                                                            has
                                                                                                                                            been
                                                                                                                                            updated</Typography>
        <Typography variant="body2">{`Your subscription has been adjusted to ${maximumNumberOfBusinessesToSelect} business${maximumNumberOfBusinessesToSelect > 1 ? 'es' : ''}.`}</Typography>
        <Typography variant="body2">{`Please confirm the ${maximumNumberOfBusinessesToSelect} business${maximumNumberOfBusinessesToSelect > 1 ? 'es' : ''} that will remain active on your account.`}</Typography>

        {isEmpty( businesses ) && <CircularProgress/>}
        {!isEmpty( businesses ) && <List dense sx={{ width: '100%' }}>
          {map( businesses, ( business ) =>
          {
            const labelId = `checkbox-list-secondary-label-${business.slug}`;
            const rowBackground = businessSlugsToEnable.includes( business.slug ) ? 'background.paper' : 'darkgrey';
            return (
              <ListItem sx={{ bgcolor: rowBackground }}
                        key={business.slug}
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            onChange={handleToggle( business.slug )}
                            checked={businessSlugsToEnable.includes( business.slug )}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        }
                        disablePadding
              >
                <ListItemButton>
                  <ListItemAvatar>
                    <BusinessAvatar business={business}/>
                  </ListItemAvatar>
                  <ListItemText id={labelId} primary={businessHelper.getBusinessNameToDisplay( business )}/>
                </ListItemButton>
              </ListItem>
            );
          } )}
        </List>}
        <LoadingButton variant="contained" color="primary"
                       onClick={handleClick}
                       loading={confirmButtonLoading}
                       disabled={!shouldEnable()}>
          Confirm
        </LoadingButton>
        <Typography variant="body2"><Link href={ALKAI_FAQ_URL} target={"_blank"}>Need help?</Link></Typography>
      </Stack>
      <AlertBanner/>
    </FullPageMobileContainer>
  )
}

export const SelectBusinessesDialog = withFullScreenDialog( SelectBusinessesDialogContent )
