import React from 'react'
import { Button, Drawer, Stack, Typography } from "@mui/material";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { facebookServices } from "../../services/facebook.services";
import { getFacebookAppId } from '../../constants';
import { ConfirmFacebookAccount } from '../confirmSocialNetworkAccount/confirmFacebookAccount';
import { businessServices } from "../../services/business.services";
import { ConfirmInstagramAccount } from "../confirmSocialNetworkAccount/confirmInstagramAccount";
import { setAlertMessage, warningAlert } from "../alert/alertSlice";
import { useDispatch } from "react-redux";
import { eventTracker } from "../../helpers/eventTracker";
import InstagramIcon from "@mui/icons-material/Instagram";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { ShareDestinationToggle } from "../editing/ShareDestinationToggle";
import { logToConsole, logToConsoleError } from "../utils/devLoggingHelper";

interface ReactFacebookRenderProps
{
  isSdkLoaded?: boolean;
  isProcessing?: boolean;
  isDisabled?: boolean;
  onClick( event: React.MouseEvent ): void;
}

interface ConnectFacebookInstagramButtonProps
{
  fromSettings?: boolean;
  handleConnectionSucceeded?(): void;
  handleConnectionFailed?(): void;
  handleConnectionStarted?(): void;
}

export function ConnectFacebookInstagramButton( props: ConnectFacebookInstagramButtonProps )
{
  const dispatch = useDispatch();
  const facebookPermissionsScope = facebookServices.getInstagramScope();
  const [drawerOpened, setDrawerOpened] = React.useState( false );
  const [profileUrl, setProfileUrl] = React.useState( "" );
  const [profileName, setProfileName] = React.useState( "" );
  const [profileAccessToken, setProfileAccessToken] = React.useState( "" );
  const [profileId, setProfileId] = React.useState( 0 );
  const [showLoader, setShowLoader] = React.useState( false );
  const [confirmingInstagram, setConfirmingInstagram] = React.useState( false );
  const socialNetworkAccountsContext = React.useContext( SocialNetworkAccountsContext );

  const handleClick = ( event: React.MouseEvent ) =>
  {
    requestConnect();
  }

  const onSwitchAccounts = ( event: React.MouseEvent ) =>
  {
    logToConsole( "switch accounts facebook instagram" );
    window.FB.logout();
    dispatch( setAlertMessage( warningAlert( "Please log out of Facebook and Instagram in your browser before trying to connect again." ) ) );
    handleCancelConnectionAttempt();
  }

  function instagramConnectionComplete()
  {
    eventTracker.logInstagramConnected();
    if ( !!props.handleConnectionSucceeded )
    {
      props.handleConnectionSucceeded();
    }
  }

  const onConfirm = async ( event: React.MouseEvent ) =>
  {
    setShowLoader( true );
    try
    {
      const result = await businessServices.clientFacebookConnect( profileId, profileAccessToken );
      logToConsole( "result facebook connected", result );

      if ( result.has_instagram_accounts )
      {
        const socialNetworkAccountsResponseAPI = await businessServices.listSocialNetworkAccounts();
        socialNetworkAccountsContext.updateSocialNetworkAccounts( socialNetworkAccountsResponseAPI.social_network_accounts );
        setShowLoader( false );
        setConfirmingInstagram( true );
      }
      else
      {
        setShowLoader( false );
        closeDrawer();
        instagramConnectionComplete();
      }
    }
    catch (error)
    {
      logToConsoleError( "error instagram connected", error );
      setShowLoader( false );
      closeDrawer();
    }
  }

  const onFacebookConnectSuccess = ( facebookId: number,
                                     name: string,
                                     facebookAccessToken: string ) =>
  {
    const profileImageUrl = facebookServices.getFacebookProfileImageUrl( facebookId.toString() );
    setProfileUrl( profileImageUrl );
    setProfileName( name );
    setProfileAccessToken( facebookAccessToken );
    setProfileId( facebookId );
    openDrawer();
  }

  const onFacebookConnectFailure = ( failureData?: { response?: string, errorText?: string, permissionsDenied?: boolean } ) =>
  {
    logToConsoleError( "facebook connect failure", failureData );
    if ( !!props.handleConnectionFailed )
    {
      props.handleConnectionFailed();
    }
  }

  const handleFacebookResponse = async ( response ) =>
  {
    if ( response && response.id && response.accessToken )
    {
      const accessToken = response.accessToken;
      const facebookIsAccessible = await facebookServices.verifyUserHasInstagramBusinessPermissions( accessToken );
      if ( facebookIsAccessible )
      {
        onFacebookConnectSuccess( response.id, response.name, accessToken );
      }
      else
      {
        onFacebookConnectFailure(
          { permissionsDenied: true, errorText: "User did not grant required Facebook permissions." },
        );
      }
    }
    else
    {
      onFacebookConnectFailure( { response, errorText: "Facebook connect failed." } );
    }
  }

  function requestConnect()
  {
    logToConsole( "connect facebook instagram started" );
    eventTracker.logAcceptConnectInstagramClicked();
    if ( !!props.handleConnectionStarted )
    {
      props.handleConnectionStarted();
    }
  }

  function reloadSocialNetworkAccounts()
  {
    businessServices.listSocialNetworkAccounts().then( ( socialNetworkAccountsResponseAPI ) =>
      {
        socialNetworkAccountsContext.updateSocialNetworkAccounts( socialNetworkAccountsResponseAPI.social_network_accounts );
      }
    );
  }

  return (
    <FacebookLogin
      appId={getFacebookAppId()}
      className={""}
      autoLoad={false}
      reAuthenticate={true}
      disableMobileRedirect={true}
      scope={facebookPermissionsScope}
      fields="name,email,picture"
      version={facebookServices.GRAPH_API_VERSION}
      render={( renderProps: ReactFacebookRenderProps ) =>
      {
        const { onClick } = renderProps;
        return (
          <>
            <Drawer
              anchor={"bottom"}
              open={drawerOpened}
              onClose={handleCancelConnectionAttempt}
              sx={{ textAlign: 'center', m: 1, zIndex: 1500 }}
            >
              {!confirmingInstagram && <ConfirmFacebookAccount profileUrl={profileUrl}
                                                               profileName={profileName}
                                                               onSwitchAccounts={onSwitchAccounts}
                                                               onConfirm={onConfirm}
                                                               showLoader={showLoader}/>}

              {confirmingInstagram && <ConfirmInstagramAccount handleCancel={handleFinishedConfirmingInstagram}
                                                               isInitialConnect={true}/>}
            </Drawer>
            {!props.fromSettings && <ShareDestinationToggle icon={<InstagramIcon sx={{ width: 30, height: 30, mt: 3, color: "black" }}/>}
                                                            label={"Connect an Instagram Account*"}
                                                            onClick={onClick}
                                                            checked={false}
            />
            }
            {
              props.fromSettings && <Stack
                                   sx={{ mt: 10, display: "flex", flexDirection: "row", gap: "10px", justifyContent: "space-between", width: "100%" }}>
                                   <Stack sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                                     <InstagramIcon sx={{ width: 30, height: 30, color: "black" }}/>
                                     <Typography>Instagram*</Typography>
                                   </Stack>
                                   <Button variant={"contained"} onClick={onClick} sx={{ minWidth: "100px" }}>Connect</Button>
                                 </Stack>
            }
          </>)
          ;
      }}
      onClick={handleClick}
      callback={handleFacebookResponse}/>
  )

  function handleFinishedConfirmingInstagram()
  {
    reloadSocialNetworkAccounts();
    instagramConnectionComplete();
    closeDrawer();
  }

  function handleCancelConnectionAttempt()
  {
    onFacebookConnectFailure();
    closeDrawer();
  }

  function closeDrawer()
  {
    setDrawerOpened( false );
  }

  function openDrawer()
  {
    setDrawerOpened( true );
  }

}
