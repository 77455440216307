import { deleteToApi, getToApi, postJsonToApi, postToApi } from "./requestManager";
import {
  ENDPOINT_AUTH_CONVERT_GUEST_TO_USER,
  ENDPOINT_AUTH_SIGN_IN,
  ENDPOINT_AUTH_SIGN_OUT,
  ENDPOINT_AUTH_VERIFY_GUEST,
  ENDPOINT_AUTH_WRONG_SIGN_IN_INPUT,
  ENDPOINT_REFRESH_USERS_ME,
  ENDPOINT_UPDATE_CLIENT_ACTIVITY,
  ROUTES,
} from "../features/constants";
import { RootState, store } from "../app/store";
import { isGuestUser, isUserLoggedIn, isUserVerificationRequired, setUser, updateUserVerificationRequired } from "../features/user/userSlice";
import { businessServices, RefreshBusinessAPI } from "./business.services";
import { eventTracker } from "../helpers/eventTracker";
import { chatServices } from "./chat.services";
import { currentUserBusinessId } from "../features/business/businessSlice";
import { errorReporter } from "../features/error/errorReporter";
import {
  getChannelTrackingParameters,
  getGAClientId,
  getLastReportedClientActivityAt,
  setLastReportedClientActivityAt
} from "../features/tracking/trackingSlice";
import { ExistingUserChoice, KEEP_EXISTING_USER } from "../features/registration/existingUserChoiceDialog";
import { apptimizeVariables } from "../features/apptimize/apptimizeVariables";
import { isUndefined, merge } from "lodash";
import { logToConsoleError } from "../features/utils/devLoggingHelper";

export const authenticationServices = {
  createAccountOrSignIn,
  signInAsGuest,
  verifyAndSignIn,
  wrongSignInInput,
  signOut,
  resendCode,
  refreshUser,
  refreshUserIfLoggedIn,
  periodicallyUpdateUserLastSeen,
}

interface SignInAPI
{
  verification_required?: string;
  data?: UserApiData;
}

interface VerifyUserAPI
{
  data: UserApiData
  existing_user?: UserApiData
}

export interface UserApiData
{
  allow_password_change: boolean;
  current_business: RefreshBusinessAPI;
  has_finished_posts: boolean;
  email: string;
  slug: string;
  image: string;
  name: string;
  nickname: string;
  phone_last_verified_at: string;
  phone_number: string;
  phone_verification_code: string;
  provider: string;
  uid: string;
  is_guest?: boolean;
  is_subscribed?: boolean;
  created_at?: string;
  last_posted_at?: string;
  number_of_posts?: number;
  current_business_name?: string;
  number_of_businesses?: number;
  max_number_of_businesses?: number;
}

async function createAccountOrSignIn( inputValue: string, signInProvider: string ): Promise<SignInAPI>
{
  const state = store.getState();
  const endPoint = isGuestUser( state ) ? ENDPOINT_AUTH_CONVERT_GUEST_TO_USER : "auth";
  const commonSignInParameters = getCommonSignInParameters( state );

  const postBody = {
    sign_in_input: sanitizedSignInInput( inputValue ),
    provider: signInProvider,
    ...commonSignInParameters
  }
  return await postJsonToApi<SignInAPI>( endPoint, {}, postBody );
}

async function periodicallyUpdateUserLastSeen()
{
  const state = store.getState();
  const oneHourInMilliseconds = 60 * 60 * 1000;
  const reportingInterval = oneHourInMilliseconds;

  const lastReportedUserActivityAt = getLastReportedClientActivityAt( state );
  const isEligibleToReportAgain = (lastReportedUserActivityAt + reportingInterval) < new Date().getTime();
  const shouldUpdateLastSeen = isUserLoggedIn( state ) && isEligibleToReportAgain;
  if ( shouldUpdateLastSeen )
  {
    const newReportedTime = new Date().getTime();
    store.dispatch( setLastReportedClientActivityAt( newReportedTime ) );
    try
    {
      await postToApi( ENDPOINT_UPDATE_CLIENT_ACTIVITY );
    }
    catch (error)
    {
      errorReporter.reportErrorToSentry( "Error reporting client activity to server" );
    }
  }
}

async function signInAsGuest(): Promise<SignInAPI>
{
  const endPoint = "auth";
  const state = store.getState();
  const commonSignInParameters = getCommonSignInParameters( state );
  const postBody = {
    sign_in_as_guest: true,
    ...commonSignInParameters
  };

  const response = await postJsonToApi<SignInAPI>( endPoint, {}, postBody );
  if ( response.data )
  {
    storeUserData( response.data );
    storeBusinessData( response.data );
  }
  eventTracker.logGuestSignInSucceeded();
  return response;
}

function getCommonSignInParameters( state: RootState )
{
  return merge({
    ga_client_id: getGAClientId( state ),
    channel_tracking_parameters: getChannelTrackingParameters( state )},
    apptimizeVariables.getApptimizeVariablesForServerRequestParams())
}

async function verifyAndSignIn( signInInput: string, provider: string, verificationCode: string, existingUserChoice?: ExistingUserChoice )
{
  const state = store.getState();
  const commonSignInParameters = getCommonSignInParameters( state );
  const postBody = {
    sign_in_input: sanitizedSignInInput( signInInput ),
    provider: provider,
    verification_code: verificationCode,
    existing_user_choice: existingUserChoice,
    handle_existing_user_choice: true,
    ...commonSignInParameters
  }
  let path;
  if ( existingUserChoice === KEEP_EXISTING_USER )
  {
    path = ENDPOINT_AUTH_SIGN_IN
  }
  else
  {
    path = isGuestUser( state ) ? ENDPOINT_AUTH_VERIFY_GUEST : ENDPOINT_AUTH_SIGN_IN;
  }

  const signInResult = await postJsonToApi<VerifyUserAPI>( path, {}, postBody );
  if ( signInResult.existing_user )
  {
    return signInResult;
  }
  if ( signInResult.data )
  {
    const userData = signInResult.data;
    storeUserData( userData );
    storeBusinessData( userData );
    store.dispatch( updateUserVerificationRequired( null ) )
    await checkForNewMessagesOnCurrentThread();
  }

  return signInResult;
}

async function wrongSignInInput( signInInput: string, signInProvider: string )
{
  if ( isUserLoggedIn( store.getState() ) && isUserVerificationRequired( store.getState() ) )
  {
    const queryParams = {
      sign_in_input: sanitizedSignInInput( signInInput ),
      provider: signInProvider
    }

    const signInResult = await postToApi<SignInAPI>( ENDPOINT_AUTH_WRONG_SIGN_IN_INPUT, queryParams );
    if ( signInResult.data )
    {
      storeUserData( signInResult.data );
    }
    return signInResult;
  }
  else
  {
    return Promise.resolve();
  }
}

function storeUserData( userData: UserApiData )
{
  store.dispatch( setUser( userData ) );
  if ( !!userData.slug )
  {
    eventTracker.setUserId( userData.slug );
  }
}

function storeBusinessData( userData: UserApiData )
{
  const localBusinessId = currentUserBusinessId( store.getState() );

  const currentBusinessData = userData.current_business;
  if ( currentBusinessData && currentBusinessData.business_id )
  {
    if ( !localBusinessId || currentBusinessData.business_id === localBusinessId )
    {
      businessServices.updateBusinessInState( currentBusinessData );
    }
    else
    {
      logToConsoleError(`Skip update business since localBusinessId (${localBusinessId}) does not match incoming business id: ${currentBusinessData.business_id}`);
    }
  }
  else
  {
    throw new Error( "Cannot update business since it's missing from user data." )
  }
}

async function checkForNewMessagesOnCurrentThread()
{
  if ( !!currentUserBusinessId( store.getState() ) )
  {
    await chatServices.checkForNewMessages( 0 );
  }
  else
  {
    errorReporter.reportErrorToSentry( new Error( "Current business is not set" ) );
    return Promise.reject( "Current business is not set!" );
  }
}

function signOut()
{
  return deleteToApi<VerifyUserAPI>( ENDPOINT_AUTH_SIGN_OUT );
}

function resendCode( signInInput: string, provider: string )
{
  const path = isGuestUser( store.getState() ) ? ENDPOINT_AUTH_CONVERT_GUEST_TO_USER : ENDPOINT_AUTH_SIGN_IN;
  const queryParams = {
    sign_in_input: sanitizedSignInInput( signInInput ),
    provider: provider
  }
  return postToApi( path, queryParams );
}

function sanitizedSignInInput( signInInput: string )
{
  return signInInput.replace( /\s+/g, "" );
}

async function refreshUser()
{
  const businessId = currentUserBusinessId( store.getState() );
  const userData = await getToApi<UserApiData>( ENDPOINT_REFRESH_USERS_ME, { business_id: businessId } );

  if ( !!userData )
  {
    storeUserData( userData );
    storeBusinessData( userData );
  }

  if ( window.location.pathname !== ROUTES.SELECT_BUSINESSES )
  {
    const canCalculateNeedToShowSelectBusiness = !isUndefined( userData.number_of_businesses ) && !isUndefined( userData.max_number_of_businesses );
    if ( canCalculateNeedToShowSelectBusiness )
    {
      // @ts-ignore to avoid IDE yelling about things it does not know
      if ( userData.number_of_businesses > userData.max_number_of_businesses )
      {
        window.location.replace( ROUTES.SELECT_BUSINESSES );
      }
    }
  }

  return userData;
}

async function refreshUserIfLoggedIn()
{
  if ( isUserLoggedIn( store.getState() ) )
  {
    return refreshUser();
  }
}
